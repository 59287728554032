import React, { useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import BeSafeTabTitle from 'components/commons/BeSafeTabTitle';

const BeSafeTabs = (props) => {
  const [key, setKey] = useState(props.defaultActiveKey);

  useEffect(() => {
    setKey(props.defaultActiveKey);
  }, [props.defaultActiveKey]);

  const onSelectTab = (tab) => {
    setKey(tab);
    props.onSelectTab(tab);
  };

  return (
    <Tabs
      defaultActiveKey={props.defaultActiveKey}
      id={props.id}
      activeKey={key}
      onSelect={(k) => onSelectTab(k)}
      className={`${props.className} thick-border-bottom mb-3 justify-content-start`}
      mountOnEnter={true}
      unmountOnExit={true}
    >
      {props.tabs.map((tab) => (
        <Tab
          key={tab.key}
          eventKey={tab.key}
          title={<BeSafeTabTitle icon={tab.icon} title={tab.title} />}
          className="content-small"
        >
          {tab.content}
        </Tab>
      ))}
    </Tabs>
  );
};

BeSafeTabs.defaultProps = {
  className: '',
  onSelectTab: () => {},
};

export default BeSafeTabs;
