import axios from 'axios';

const BASE_DEV_URL = '/devices';
const BASE_LICENSE_URL = '/accounts';
const BASE_SYSLOG_URL = '/syslogs';
const BASE_INTERFACES_URL = '/interfaces';
const BASE_DNS_CONFIG_URL = '/dns_configs';
const BASE_STATIC_ROUTES_URL = '/static_routes';
const BASE_DHCP_CONFIGS_URL = '/dhcp_configs';

export function getAllDevices(params) {
  return axios.get(`${BASE_DEV_URL}`, { params: params });
}

export function getDashboardDevices(params) {
  return axios.get(`${BASE_DEV_URL}/dashboard_devices`, {
    params: params,
  });
}

export function getCloudStatus() {
  return axios.get(`${BASE_DEV_URL}/cloud_status`);
}

export function getDevice(params) {
  return axios.get(`${BASE_DEV_URL}/${params}`, {});
}

export function newDevice(params) {
  return axios.post(`${BASE_DEV_URL}/`, { device: params });
}

export function deleteDevice(id, params) {
  return axios.delete(`${BASE_DEV_URL}/` + id, { params });
}

export function getInfoLicenses(id) {
  return axios.get(`${BASE_LICENSE_URL}/` + id, {});
}

export function getTeldatModels() {
  return axios.get(`${BASE_DEV_URL}/models`, {});
}

export function checkNameDevice(name) {
  return axios.post(`${BASE_DEV_URL}/name`, {
    name: name,
  });
}

export function updateDevice(device) {
  return axios.patch(`${BASE_DEV_URL}/${device.id}`, {
    device: device,
  });
}

export function getMonitor(id) {
  return axios.get(`${BASE_DEV_URL}/${id}/monitor`, {});
}

export function getDeviceLogs(id) {
  return axios.get(`${BASE_DEV_URL}/${id}/logs`, {});
}

export function getInterfaces(id) {
  return axios.get(`${BASE_DEV_URL}/${id}${BASE_INTERFACES_URL}`, {});
}

export function createInterface(id, params) {
  return axios.post(`${BASE_DEV_URL}/${id}${BASE_INTERFACES_URL}`, params);
}

export function updateInterface(device_id, id, params) {
  return axios.patch(
    `${BASE_DEV_URL}/${device_id}${BASE_INTERFACES_URL}/${id}`,
    params
  );
}

export function deleteInterface(device_id, id) {
  return axios.delete(
    `${BASE_DEV_URL}/${device_id}${BASE_INTERFACES_URL}/${id}`,
    {}
  );
}

export function getStaticRoutes(id, params) {
  return axios.get(`${BASE_DEV_URL}/${id}${BASE_STATIC_ROUTES_URL}`, {
    params: params,
  });
}

export function createStaticRoute(id, params) {
  return axios.post(`${BASE_DEV_URL}/${id}${BASE_STATIC_ROUTES_URL}`, params);
}

export function updateStaticRoute(device_id, id, params) {
  return axios.patch(
    `${BASE_DEV_URL}/${device_id}${BASE_STATIC_ROUTES_URL}/${id}`,
    params
  );
}

export function deleteStaticRoute(device_id, id) {
  return axios.delete(
    `${BASE_DEV_URL}/${device_id}${BASE_STATIC_ROUTES_URL}/${id}`,
    {}
  );
}

export function createAllDevices(params) {
  return axios.post(`${BASE_DEV_URL}/create_all`, params);
}

export function checkCloudLicense(id) {
  return axios.get(`${BASE_DEV_URL}/tennant/exist`);
}

export function exportDevices() {
  return axios.get(`${BASE_DEV_URL}/export`);
}

export function getConfigurationDifferences(id) {
  return axios.get(`${BASE_DEV_URL}/${id}/configuration_differences`);
}

export function getConfiguration(id) {
  return axios.get(`${BASE_DEV_URL}/${id}/configuration`);
}

export function getFirewallCLI(id) {
  return axios.get(`${BASE_DEV_URL}/${id}/firewall_cli`);
}

export function getSyslogs(device_id) {
  return axios.get(`${BASE_DEV_URL}/${device_id}${BASE_SYSLOG_URL}`, {});
}

export function createSyslog(device_id, params) {
  return axios.post(`${BASE_DEV_URL}/${device_id}${BASE_SYSLOG_URL}`, params);
}

export function updateSyslog(device_id, syslog_id, params) {
  return axios.patch(
    `${BASE_DEV_URL}/${device_id}${BASE_SYSLOG_URL}/${syslog_id}`,
    params
  );
}

export function deleteSyslog(device_id, syslog_id) {
  return axios.delete(
    `${BASE_DEV_URL}/${device_id}${BASE_SYSLOG_URL}/${syslog_id}`,
    {}
  );
}

export function importDevices(params) {
  return axios.post(`${BASE_DEV_URL}/import`, params);
}

export function getDnsConfigs(id) {
  return axios.get(`${BASE_DEV_URL}/${id}${BASE_DNS_CONFIG_URL}`, {});
}

export function updateDnsConfig(device_id, id, params) {
  return axios.patch(
    `${BASE_DEV_URL}/${device_id}${BASE_DNS_CONFIG_URL}/${id}`,
    params
  );
}

export function getDhcpConfigs(deviceId) {
  return axios.get(`${BASE_DEV_URL}/${deviceId}${BASE_DHCP_CONFIGS_URL}`);
}

export function createDhcpConfig(deviceId, params) {
  return axios.post(
    `${BASE_DEV_URL}/${deviceId}${BASE_DHCP_CONFIGS_URL}`,
    params
  );
}

export function updateDhcpConfig(deviceId, dhcpConfigId, params) {
  return axios.patch(
    `${BASE_DEV_URL}/${deviceId}${BASE_DHCP_CONFIGS_URL}/${dhcpConfigId}`,
    params
  );
}

export function deleteDhcpConfig(deviceId, dhcpConfigId) {
  return axios.delete(
    `${BASE_DEV_URL}/${deviceId}${BASE_DHCP_CONFIGS_URL}/${dhcpConfigId}`
  );
}

export function clearExpiredLicenses(deviceId) {
  return axios.post(`${BASE_DEV_URL}/${deviceId}/clear_expired_licenses`);
}
