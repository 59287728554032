import { Cancel } from '@mui/icons-material';
import React from 'react';

const BeSafeSelectorTag = ({ index, tag, cancellable, removeTags }) => {
  return (
    <>
      <li key={index} className="tag">
        <span className="ellipsable">{tag.name}</span>
        {cancellable && (
          <span onClick={() => removeTags(index)}>
            <Cancel className="tag-close-icon" />
          </span>
        )}
      </li>
    </>
  );
};

export default BeSafeSelectorTag;
