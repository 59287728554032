import React from 'react';
import { Button } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import BeSafeTooltip from './BeSafeTooltip';

const BeSafeButton = ({
  visible,
  tooltip,
  variant,
  type,
  className,
  onClick,
  disabled,
  href,
  target,
  rel,
  style,
  icon,
  text,
  size,
  iconPosition,
}) => {
  return visible ? (
    <BeSafeTooltip tooltip={tooltip ? <FormattedMessage id={tooltip} /> : ''}>
      <Button
        variant={variant}
        type={type}
        className={className}
        onClick={onClick}
        disabled={disabled}
        href={href}
        target={target}
        rel={rel}
        style={style}
        size={size}
      >
        {iconPosition === 'left' && icon}
        {text}
        {iconPosition === 'right' && icon}
      </Button>
    </BeSafeTooltip>
  ) : null;
};

BeSafeButton.defaultProps = {
  visible: true,
  type: 'button',
  iconPosition: 'left',
};
export default BeSafeButton;
