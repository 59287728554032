import axios from 'axios';

export const LOGIN_URL = 'sign_in';
export const LOGOUT_URL = 'sign_out';

export function login(email) {
  return axios.post(LOGIN_URL, { session: { email } });
}

export function logout(delete_all_sessions = true) {
  return axios.delete(LOGOUT_URL, { data: { delete_all_sessions } });
}
