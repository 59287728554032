import React, { useState, useEffect, useCallback } from 'react';
import { ListGroup, Image } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import LanOutlinedIcon from '@mui/icons-material/LanOutlined';
import PersonIcon from '@mui/icons-material/Person';
import DnsIcon from '@mui/icons-material/Dns';

import { deleteNetwork, getNetworks } from 'app/crud/networks.crud';
import { successToast, errorToast, warningToast } from 'utils/toastUtils';
import { ConfirmationPopup } from 'components/ConfirmDialog';
import NetworkSubList from 'components/networks/NetworkSubList';
import NetworkRangeSubList from 'components/networks/NetworkRangeSubList';
import FQDNSubList from 'components/networks/FQDNSubList';
import MACSubList from 'components/networks/MACSubList';
import UserSubList from 'components/networks/UserSubList';
import GeolocationSubList from 'components/networks/GeolocationSubList';
import BeSafeSearchBar from 'components/commons/BeSafeSearchBar';
import TableLoader from 'components/commons/TableLoader';
import NoDataDisplay from 'components/commons/NoDataDisplay';
import BeSafeContainer from 'components/commons/BeSafeContainer';
import FirewallADConfiguration from 'components/configuration/firewall/FirewallADConfiguration';
import CasbTenantSubList from './CasbTenantSubList';
import AddObjectModal from './AddObjectModal';
import { Add } from '@mui/icons-material';
import BeSafeButton from 'components/commons/BeSafeButton';
import { getAzureAdConfigs } from 'app/crud/azure_ad_config.crud';
import { syncAzureAdUsers } from 'app/crud/azure_ad_config.crud';

const NetworkObjectList = (props) => {
  const [networks, setNetworks] = useState([]);
  const [selectedNetwork, setSelectedNetwork] = useState([]);
  const usedList = [];

  const [editMode, setEditMode] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [previewLoading, setPreviewLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');

  const [showAzureUserModal, setShowAzureUserModal] = useState(false);
  const [azureUsersList, setAzureUsersList] = useState([]);
  const [azureADConfig, setAzureADConfig] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const intl = useIntl();

  const selectNetwork = useCallback((network) => {
    setPreviewLoading(true);
    setSelectedNetwork(network);
    setPreviewLoading(false);
  }, []);

  const fetchAzureADConfigs = useCallback(() => {
    getAzureAdConfigs()
      .then((response) => {
        setAzureADConfig(
          response.data.data.map((vpn_config) => {
            return {
              ...vpn_config.attributes,
            };
          })
        );
      })
      .catch((error) => {
        errorToast({ intl: intl });
      });
  }, [intl]);

  const onSubmitAzureUsers = (event, id) => {
    event.preventDefault();
    if (!id) {
      errorToast({ body: 'USER_OBJECTS.NO_AZURE_CONFIG', intl: intl });
      return;
    }
    setIsSubmitting(true);
    syncAzureAdUsers(id)
      .then((response) => {
        if (!response?.data || !Object.keys(response?.data)) {
          errorToast({ body: 'USER_OBJECTS.NO_DATA', intl: intl });
        } else {
          setAzureUsersList(response?.data);
          setShowAzureUserModal(true);
          successToast({
            body: 'USER_OBJECTS.ADD_SUCCESS',
            intl: intl,
          });
        }
        onCreateNetwork();
      })
      .catch((error) => {
        errorToast({ body: error.response?.data?.error, intl: intl });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  useEffect(() => fetchAzureADConfigs(), [fetchAzureADConfigs]);

  const fetchNetworks = useCallback(
    (q) => {
      setLoading(true);
      setPreviewLoading(true);
      getNetworks({ type: props?.type, q })
        .then((response) => {
          setNetworks(response.data.objects);
          if (response.data.objects.length) {
            selectNetwork(response.data.objects[0]);
          }
        })
        .catch((error) => {
          errorToast({ body: 'NETWORK_OBJECTS.LOAD_ERROR', intl: intl });
        })
        .finally(() => {
          setLoading(false);
          setPreviewLoading(false);
        });
    },
    [intl, selectNetwork, props?.type]
  );

  useEffect(() => {
    fetchNetworks(searchQuery);
  }, [fetchNetworks, searchQuery]);

  const showEditNetworkModal = () => {
    setEditMode(true);
    setShowModal(true);
  };

  const showNewNetworkModal = () => {
    setEditMode(false);
    setShowModal(true);
  };

  const deleteNetworkObject = () => {
    if (usedList.length > 0) {
      warningToast({
        body: 'NETWORK_OBJECTS.ALREADY_IN_USE',
        intl: intl,
      });
    } else {
      ConfirmationPopup({
        title: intl.formatMessage({
          id: 'GENERAL.WARNING',
        }),
        description: intl.formatMessage({
          id: 'GENERAL.CONFIRM_DELETE',
        }),
        okLabel: intl.formatMessage({
          id: 'GENERAL.OK',
        }),
        cancelLabel: intl.formatMessage({
          id: 'GENERAL.CANCEL',
        }),
        okAction: () => {
          deleteNetwork(selectedNetwork.id)
            .then((response) => {
              let vals = networks.filter((nw) => nw.id !== selectedNetwork.id);
              setNetworks(vals);
              if (vals.length) selectNetwork(vals[0]);
              successToast({
                body: 'NETWORK_OBJECTS.DELETE_SUCCESS',
                intl: intl,
              });
            })
            .catch((err) => {
              if (err.response.data.already_in_used) {
                errorToast({
                  body: 'NETWORK_OBJECTS.ALREADY_IN_USE',
                  intl: intl,
                });
              } else {
                errorToast({
                  body: err.response.data?.error,
                  intl: intl,
                });
              }
            });
        },
      });
    }
  };

  const onCreateNetwork = () => {
    fetchNetworks(searchQuery);
    setShowModal(false);
  };

  return (
    <div className="d-flex obj-cus-h-35 py-2">
      <div className="d-flex card flex-column w-40 h-100 mr-3">
        {props.type !== 'geolocation' && (
          <div className="d-flex justify-content-between pl-3">
            <BeSafeSearchBar onSearch={setSearchQuery} />
            <BeSafeButton
              variant="transparent"
              className="py-0 ml-auto px-1"
              onClick={showNewNetworkModal}
              icon={<Add />}
              tooltip="GENERAL.ADD"
            />
          </div>
        )}
        <div className="flex-grow-1 overflow-auto">
          <TableLoader visible={loading} rows={10} />
          <NoDataDisplay visible={!loading && networks.length <= 0} />
          <BeSafeContainer
            visible={
              !loading &&
              ((networks.length > 0 && props.type !== 'user') ||
                props.type === 'user')
            }
          >
            <div className="d-flex flex-column h-100">
              <ListGroup className="flex-grow-1 overflow-auto">
                {networks.map((network) => (
                  <ListGroup.Item
                    className="rounded-0"
                    active={network.id === selectedNetwork.id}
                    key={network.id}
                    action
                    onClick={() => selectNetwork(network)}
                  >
                    {['network', 'network_range', 'mac'].includes(
                      props.type
                    ) && <LanOutlinedIcon className="mr-2" />}
                    {['user'].includes(props.type) && (
                      <PersonIcon className="mr-2" />
                    )}
                    {['fqdn'].includes(props.type) && (
                      <DnsIcon className="mr-2" />
                    )}
                    {['geolocation'].includes(props.type) && (
                      <>
                        <Image
                          src={`/images/flags/flag-icons/1x1/${network.description?.toLowerCase()}.svg`}
                          style={{ width: '15px' }}
                        />{' '}
                      </>
                    )}
                    {props.type === 'geolocation' ? (
                      <>
                        <FormattedMessage
                          id={`COUNTRIES.${network.description.toUpperCase()}`}
                        />
                      </>
                    ) : (
                      network.name
                    )}
                  </ListGroup.Item>
                ))}
              </ListGroup>
              {props.type === 'user' && (
                <FirewallADConfiguration
                  showAzureUserModal={showAzureUserModal}
                  setShowAzureUserModal={setShowAzureUserModal}
                  azureUsersList={azureUsersList}
                  azureADConfig={azureADConfig}
                  onSubmit={onSubmitAzureUsers}
                  isSubmitting={isSubmitting}
                />
              )}
            </div>
          </BeSafeContainer>
        </div>
      </div>
      {props.type === 'network' && (
        <>
          <NetworkSubList
            previewLoading={previewLoading}
            networks={networks}
            selectedNetwork={selectedNetwork}
            deleteNetworkObject={deleteNetworkObject}
            showEditNetworkModal={showEditNetworkModal}
          />
        </>
      )}
      {props.type === 'network_range' && (
        <>
          <NetworkRangeSubList
            previewLoading={previewLoading}
            networkRanges={networks}
            selectedNetworkRange={selectedNetwork}
            deleteNetworkObject={deleteNetworkObject}
            showEditNetworkModal={showEditNetworkModal}
          />
        </>
      )}
      {props.type === 'fqdn' && (
        <>
          <FQDNSubList
            previewLoading={previewLoading}
            fqdns={networks}
            selectedFQDN={selectedNetwork}
            deleteNetworkObject={deleteNetworkObject}
            showEditNetworkModal={showEditNetworkModal}
          />
        </>
      )}
      {props.type === 'mac' && (
        <>
          <MACSubList
            previewLoading={previewLoading}
            macs={networks}
            selectedMAC={selectedNetwork}
            deleteNetworkObject={deleteNetworkObject}
            showEditNetworkModal={showEditNetworkModal}
          />
        </>
      )}
      {props.type === 'user' && (
        <>
          <UserSubList
            previewLoading={previewLoading}
            users={networks}
            selectedUser={selectedNetwork}
            deleteNetworkObject={deleteNetworkObject}
            showEditNetworkModal={showEditNetworkModal}
            refetchNetwork={fetchNetworks}
          />
        </>
      )}
      {props.type === 'geolocation' && (
        <>
          <GeolocationSubList
            previewLoading={previewLoading}
            locations={networks}
            selectedLocation={selectedNetwork}
            deleteNetworkObject={deleteNetworkObject}
            showEditNetworkModal={showEditNetworkModal}
          />
        </>
      )}
      {props.type === 'casb_tenant' && (
        <>
          <CasbTenantSubList
            previewLoading={previewLoading}
            casbTenants={networks}
            selectedCasbTenant={selectedNetwork}
            deleteNetworkObject={deleteNetworkObject}
            showEditNetworkModal={showEditNetworkModal}
          />
        </>
      )}
      <AddObjectModal
        type={props.type}
        editMode={editMode}
        showModal={showModal}
        onHide={() => setShowModal(false)}
        onCreate={() => onCreateNetwork()}
        onUpdate={() => onCreateNetwork()}
        selectedObject={selectedNetwork}
      />
    </div>
  );
};

export default NetworkObjectList;
