export const INITIAL_PORTS = [
  {
    name: 'http',
    displayName: 'HTTP',
    mode: 'specify',
    ports: '80',
  },
  {
    name: 'https',
    displayName: 'HTTPS',
    mode: 'specify',
    ports: '443',
  },
  {
    name: 'smtp',
    displayName: 'SMTP',
    mode: 'specify',
    ports: '25',
  },
  {
    name: 'smtps',
    displayName: 'SMTPS',
    mode: 'specify',
    ports: '465',
  },
  {
    name: 'ftp',
    displayName: 'FTP',
    mode: 'any',
  },
  {
    name: 'smb',
    displayName: 'SMB',
    mode: 'specify',
    ports: '445',
  },
  {
    name: 'ssh',
    displayName: 'SSH',
    mode: 'specify',
    ports: '22',
  },
  {
    name: 'mqtt',
    displayName: 'MQTT',
    mode: 'specify',
    ports: '1883',
  },
  {
    name: 'imap',
    displayName: 'IMAP',
    mode: 'specify',
    ports: '143',
  },
  {
    name: 'pop3',
    displayName: 'POP3',
    mode: 'specify',
    ports: '110',
  },
  {
    name: 'pop3s',
    displayName: 'POP3S',
    mode: 'specify',
    ports: '995',
  },
  {
    name: 'oracle',
    displayName: 'ORACLE',
    mode: 'any',
  },
  {
    name: 'sip',
    displayName: 'SIP',
    mode: 'specify',
    ports: '5060,5061,5600',
  },
];

export const SSE_MIN_USERS = 10;
export const SSE_MAX_MULTIPLIER = 1000;

export const UNIQUE_PROFILE_FEATURES = ['ztna'];
