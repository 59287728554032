import axios from 'axios';

const BASE_URL_BESAFE_OBJECTS = '/besafe_objects';
const BASE_URL_BESAFE_GROUPS = '/besafe_groups';

// OBJECTS

export function getNetworks(params) {
  return axios.get(`${BASE_URL_BESAFE_OBJECTS}`, { params });
}

export function deleteNetwork(id) {
  return axios.delete(`${BASE_URL_BESAFE_OBJECTS}/${id}`);
}

export function createNetwork(params) {
  return axios.post(`${BASE_URL_BESAFE_OBJECTS}`, params);
}

export function updateNetwork(params) {
  return axios.patch(`${BASE_URL_BESAFE_OBJECTS}/${params.id}`, params);
}

export function whereObjectUsed(id) {
  return axios.get(`${BASE_URL_BESAFE_OBJECTS}/${id}/where_used`);
}

export function getVpnClientConfig(id) {
  return axios.get(`${BASE_URL_BESAFE_OBJECTS}/${id}/fetch_vpn_client_config`);
}

// GROUPS

export function getNetworksGroups(params) {
  return axios.get(`${BASE_URL_BESAFE_GROUPS}`, { params });
}

export function deleteNetworksGroup(id) {
  return axios.delete(`${BASE_URL_BESAFE_GROUPS}/${id}`);
}

export function createNetworksGroup(params) {
  return axios.post(`${BASE_URL_BESAFE_GROUPS}`, { besafe_group: params });
}

export function updateNetworksGroup(id, params) {
  return axios.patch(`${BASE_URL_BESAFE_GROUPS}/${id}`, {
    besafe_group: params,
  });
}
