import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Add } from '@mui/icons-material';
import _ from 'lodash';

import 'assets/scss/tags_select.scss';
import { errorToast } from 'utils/toastUtils';
import BeSafeButton from 'components/commons/BeSafeButton';
import NetworksListSelect from 'components/networks/NetworksListSelect';
import BeSafeSelectorTag from 'components/commons/BeSafeSelectorTag';

const NetworksSelect = (props) => {
  const [selectedTags, setSelectedTags] = useState(props.initialTags || []);
  const [showModal, setShowModal] = useState(false);
  const [allowedNetworks, setAllowedNetworks] = useState([]);

  const intl = useIntl();

  const validDelete = (tag) => {
    if (props.selectType === 'record') {
      if (tag.data?.search_engine) {
        errorToast({ body: 'DNS_RECORD_OBJECTS.DESELECT_NOT_ALLOWED', intl });

        return false;
      }
    }

    return true;
  };

  const removeTags = (indexToRemove) => {
    const tag = selectedTags[indexToRemove];

    if (validDelete(tag)) {
      const newTags = [
        ...selectedTags.filter((_, index) => index !== indexToRemove),
      ];
      setSelectedTags(newTags);
      if (_.isFunction(props.handleChange)) props.handleChange(newTags);
    }
  };

  const handleItemChange = (items) => {
    setSelectedTags(items);
    if (_.isFunction(props.handleChange)) props.handleChange(items);
  };

  const getAllowedNetworksByType = (type) => {
    if (type === 'source' || type === 'destination') {
      return [
        'network',
        'network_range',
        'mac',
        'fqdn',
        'geolocation',
        'user',
        'network-group',
        'network_range-group',
        'mac-group',
        'fqdn-group',
        'geolocation-group',
        'user-group',
      ];
    } else if (type === 'service') {
      return [
        'tcp_service',
        'udp_service',
        'icmp_service',
        'other_service',
        'tcp_service-group',
        'udp_service-group',
        'icmp_service-group',
        'other_service-group',
      ];
    } else if (type === 'record') {
      return ['dns_record'];
    } else if (type === 'tcp_service') {
      return [type];
    }
    return [];
  };

  useEffect(() => {
    const isEqual = _.isEqual(props.initialTags, selectedTags);

    if (!isEqual && props.initialTags) {
      setSelectedTags(props.initialTags);
    }
  }, [props.initialTags, selectedTags]);

  useEffect(() => {
    if (props.allowedNetworks) {
      setAllowedNetworks(props.allowedNetworks);
    } else {
      setAllowedNetworks(getAllowedNetworksByType(props.selectType));
    }
  }, [props.allowedNetworks, props.selectType]);

  const getDisplayedSelectedTags = () => {
    if (selectedTags.length > 0)
      return selectedTags.map((tag) => ({
        id: tag.id,
        name: tag.name,
        cancellable: props.disabled ? false : true,
      }));

    if (props.defaultTagIfEmpty) {
      let tag = 'GENERAL.ANY';

      if (typeof props.defaultTagIfEmpty === 'string') {
        tag = props.defaultTagIfEmpty;
      }

      return [
        {
          id: 0,
          name: intl.formatMessage({
            id: tag,
          }),
          cancellable: false,
        },
      ];
    }

    return [];
  };

  const displayedSelectedTags = getDisplayedSelectedTags();

  return (
    <>
      <div
        className={`d-flex ${props.disabled ? 'disabled' : ''}`}
        style={{ position: 'relative' }}
      >
        <div className={`tags-select${props.isInvalid ? ' invalid' : ''}`}>
          <ul id="tags">
            {props.limitVisibleItems && displayedSelectedTags.length > 5 ? (
              <>
                {displayedSelectedTags?.slice(0, 5).map((tag, index) => (
                  <BeSafeSelectorTag
                    key={index}
                    tag={tag}
                    index={index}
                    removeTags={removeTags}
                    cancellable={'cancellable' in tag ? tag.cancellable : true}
                  />
                ))}
                ...
              </>
            ) : (
              displayedSelectedTags?.map((tag, index) => (
                <BeSafeSelectorTag
                  key={index}
                  tag={tag}
                  index={index}
                  removeTags={removeTags}
                  cancellable={'cancellable' in tag ? tag.cancellable : true}
                />
              ))
            )}
          </ul>
        </div>
        <BeSafeButton
          className="btn-primary-transparent btn-in-box"
          onClick={() => {
            if (!props.disabled) {
              setShowModal(true);
            }
          }}
          icon={<Add />}
          variant="transparent"
        />
      </div>

      <NetworksListSelect
        showModal={showModal}
        onHide={() => {
          setShowModal(false);
        }}
        changeItems={handleItemChange}
        objectType={props.objectType}
        selectType={props.selectType}
        initialSelected={props.initialTags}
        allowedNetworks={allowedNetworks}
        networkMaskFilter={props.networkMaskFilter}
        ipProtocol={props.ipProtocol}
        limit={props.limit}
        tooltipData={props.tooltipData}
        search_protection_config={props.search_protection_config}
        onlyEncryptedServices={props.onlyEncryptedServices}
      />
    </>
  );
};

NetworksSelect.defaultProps = {
  isInvalid: false,
  errors: '',
  limit: 0,
};

export default NetworksSelect;
