import './vendor/styles/bootstrap.scss';
import './vendor/styles/appwork.scss';
import './vendor/styles/theme-teldat.scss';
import './vendor/styles/colors.scss';
import './App.scss';

import React, { useCallback, useState } from 'react';
import I18nProvider from './i18n/i18nProvider';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor } from './store';
import ThemeProvider from 'assets/materialUIThemeProvider/ThemeProvider';
import { useEffect } from 'react';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import Keycloak from 'keycloak-js';
import { GetConfig } from 'utils/config';
import Loader from 'shared/Loader';
import AuthenticatedApp from 'shared/AuthenticatedApp';
import { login } from 'app/crud/auth.crud';
import { useDispatch } from 'react-redux';
import * as auth from 'store/ducks/auth.duck';
import { setCookie } from 'utils/utils';
import { SetPolicyChanges } from 'store/ducks/policyChange.duck';

function App() {
  const [keycloak, setKeycloak] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    GetConfig()
      .then((config) => config.json())
      .then((config) => {
        const newKeycloak = new Keycloak({
          url: config.REACT_APP_AUTH_SERVER_URL,
          realm: config.REACT_APP_AUTH_SERVER_REALM,
          clientId: config.REACT_APP_AUTH_SERVER_CLIENT_ID,
        });
        setKeycloak(newKeycloak);
      });
  }, []);

  const updateSessionToken = useCallback(() => {
    setCookie('kc_session', keycloak.token);
    login(keycloak.idTokenParsed.email)
      .then((response) => {
        dispatch(auth.actions.login(response.data));
      })
      .catch(async () => {
        setCookie('kc_session', '', 'Expires=Thu, 01 Jan 1970 00:00:01 GMT;');
        dispatch(auth.actions.logout());
        dispatch(SetPolicyChanges(0));
        await keycloak.logout({
          redirectUri: keycloak.createLoginUrl({ redirectUri: '/' }),
        });
      });
  }, [dispatch, keycloak]);

  if (!keycloak) {
    return <Loader />;
  }

  return (
    <PersistGate persistor={persistor}>
      <ReactKeycloakProvider
        initOptions={{ onLoad: 'login-required' }}
        LoadingComponent={<Loader />}
        authClient={keycloak}
        onEvent={(event) => {
          if (event === 'onAuthRefreshSuccess') {
            updateSessionToken();
          }
          if (event === 'onAuthSuccess') {
            dispatch(auth.actions.logout());
            dispatch(SetPolicyChanges(0));
            updateSessionToken();
          }
        }}
      >
        <BrowserRouter>
          <ThemeProvider>
            <I18nProvider>
              <AuthenticatedApp />
            </I18nProvider>
          </ThemeProvider>
        </BrowserRouter>
      </ReactKeycloakProvider>
    </PersistGate>
  );
}

export default App;
