import React, { useState, useEffect, useCallback } from 'react';
import { injectIntl } from 'react-intl';
import { connect, shallowEqual, useSelector, useDispatch } from 'react-redux';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

import { SetSecondarySidebar } from 'store/ducks/configuration.duck';
import { findMainConfigurations } from 'app/crud/configuration.crud';
import schema from 'utils/schemas/ConfigurationSchema';
import LayoutSidebarMain from 'shared/layouts/LayoutSidebarMain';
import LayoutSidebarSecondary from 'shared/layouts/LayoutSidebarSecondary';
import BeSafeButton from 'components/commons/BeSafeButton';

const LayoutSidebar = () => {
  const [sidebarExpanded, setSidebarExpanded] = useState(false);
  const [sidebarMenu, setSidebarMenu] = useState('');
  const [configurationMenu, setConfigurationMenu] = useState([]);

  const dispatch = useDispatch();

  const { accountType, role, latestConfigurationChange } = useSelector(
    ({ auth, configuration }) => ({
      accountType: auth.user.account_type,
      role: auth.user.role,
      latestConfigurationChange: configuration.latestConfigurationChange,
    }),
    shallowEqual
  );

  const clickMenuButton = (menu) => {
    if (sidebarExpanded && sidebarMenu === menu) {
      return closeSidebar();
    }

    setSidebarMenu(menu);
    setSidebarExpanded(true);
    dispatch(SetSecondarySidebar(true));
  };

  const closeSidebar = () => {
    setSidebarExpanded(false);
    dispatch(SetSecondarySidebar(false));
  };

  const autoExpandMenu = useCallback(() => {
    const path = window.location.pathname;

    // Uncomment when XDR is activated
    if (path === '/' || path === '/reports' /*|| path === '/xdr'*/) {
      setSidebarMenu('analytic');
      setSidebarExpanded(true);
      dispatch(SetSecondarySidebar(true));
      return;
    }

    if (path.includes('configuration/')) {
      setSidebarMenu('configuration');
      setSidebarExpanded(true);
      dispatch(SetSecondarySidebar(true));
      return;
    }

    dispatch(SetSecondarySidebar(false));
  }, [dispatch]);

  const generateConfigurationSubmenu = useCallback(
    (element, dbData, currentFunctExpanded) => {
      if (element.menu) {
        element.menu = element.menu.map((option) =>
          generateConfigurationSubmenu(option, dbData, currentFunctExpanded)
        );
        element.expanded = element.menu.find((option) => option.expanded);
      }

      const database_conf = dbData.find(
        (dbConf) => dbConf.name === element.name
      );
      if (database_conf) {
        element.enable = database_conf.enable;
        element.id = database_conf.id;
        element.has_license = database_conf.has_license;
        element.license_types = database_conf.license_types;
        element.menu = database_conf.menu;
        element.expanded = element.name === currentFunctExpanded;
      }

      return element;
    },
    []
  );

  const getCurrentFunctExpanded = useCallback(() => {
    const pathname = window.location.pathname.split('/');
    pathname.pop();
    const elementName = pathname.pop();

    return elementName;
  }, []);

  useEffect(() => {
    if (accountType !== null && accountType !== 'reseller') {
      findMainConfigurations().then((response) => {
        const dbData = response.data;
        const currentFunctExpanded = getCurrentFunctExpanded();
        let newConfigurationMenu = [...schema];

        newConfigurationMenu.map((element) =>
          generateConfigurationSubmenu(element, dbData, currentFunctExpanded)
        );

        setConfigurationMenu(newConfigurationMenu);
      });
    }
  }, [
    generateConfigurationSubmenu,
    getCurrentFunctExpanded,
    accountType,
    latestConfigurationChange,
  ]);

  useEffect(() => {
    autoExpandMenu();
  }, [autoExpandMenu]);

  const checkFunctExpanded = (element, elementName) => {
    if (element.menu) {
      element.menu = element.menu.map((option) =>
        checkFunctExpanded(option, elementName)
      );
    }

    if (element.name === elementName) {
      element.expanded = !element.expanded;
    }

    return element;
  };

  const expandMenu = (configuration) => {
    const newConfigurationMenu = [...configurationMenu];
    newConfigurationMenu.map((element) =>
      checkFunctExpanded(element, configuration.name)
    );

    setConfigurationMenu(newConfigurationMenu);
  };

  const toggleSidebar = () => {
    if (sidebarExpanded) {
      return closeSidebar();
    }

    if (sidebarMenu) {
      return clickMenuButton(sidebarMenu);
    }

    clickMenuButton('analytic');
  };

  return (
    <>
      <LayoutSidebarMain
        accountType={accountType}
        role={role}
        sidebarExpanded={sidebarExpanded}
        sidebarMenu={sidebarMenu}
        clickMenuButton={clickMenuButton}
        closeSidebar={closeSidebar}
      />

      <LayoutSidebarSecondary
        accountType={accountType}
        role={role}
        configurationMenu={configurationMenu}
        sidebarMenu={sidebarMenu}
        expandMenu={expandMenu}
        closeSidebar={closeSidebar}
      />

      <BeSafeButton
        className="btn-expand"
        variant="primary"
        onClick={toggleSidebar}
        icon={<KeyboardArrowRightIcon fontSize="medium" />}
      />
    </>
  );
};

export default connect((store) => ({
  user: store.auth.user,
}))(injectIntl(LayoutSidebar));
