import React, { useState, useEffect } from 'react';
import ReactPaginate from 'react-js-pagination';
import Form from 'react-bootstrap/Form';

const Pagination = (props) => {
  const perPageValues = props.perPageValues;
  const [activePage, setActivePage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(perPageValues[0]);

  useEffect(() => {
    setActivePage(props.pageNumber);
    setItemsPerPage(props.perPage);
  }, [props]);

  const onPageChange = (pageNum) => {
    if (activePage !== pageNum) {
      setActivePage(pageNum);
      props.handlePageChange(pageNum);
    }
  };

  const onItemsPerPageChange = (itemsPerPage) => {
    setItemsPerPage(itemsPerPage);
    props.handlePerPageChange(itemsPerPage);
    onPageChange(1);
  };

  return (
    <div className="d-flex justify-content-between">
      <Form.Control
        as="select"
        onChange={(e) => onItemsPerPageChange(Number(e.target.value))}
        value={itemsPerPage}
        className="pagination-select mr-5 px-1 py-0"
      >
        {perPageValues.map((perPageVal, index) => (
          <option key={index} value={perPageVal}>
            {perPageVal}
          </option>
        ))}
      </Form.Control>
      <ReactPaginate
        activePage={activePage}
        itemsCountPerPage={itemsPerPage}
        totalItemsCount={props.totalItems}
        pageRangeDisplayed={5}
        onChange={(event) => onPageChange(event)}
        innerClass="pagination mb-0"
      />
    </div>
  );
};

Pagination.defaultProps = {
  perPageValues: [10, 50, 100],
};

export default Pagination;
