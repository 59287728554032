import React from 'react';
import { Modal, Form, Row, Col } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Formik } from 'formik';
import moment from 'moment-timezone';

import { createSchedule, updateSchedule } from 'app/crud/schedules.crud';
import { parseSchedule } from 'utils/cronUtils';
import { isValidTime } from 'utils/utils';
import BeSafeWeekDaysRow from 'components/commons/BeSafeWeekDaysRow';
import BeSafeButton from 'components/commons/BeSafeButton';
import BeSafeTimeRange from 'components/commons/BeSafeTimeRange';
import BeSafeControlFeedback from 'components/commons/BeSafeControlFeedback';
import BeSafeSwitch from 'components/commons/BeSafeSwitch';

const ScheduleModal = ({
  editMode,
  selectedSchedule,
  showModal,
  onHide,
  onCreate,
  onUpdate,
}) => {
  const intl = useIntl();

  const initialScheduleValues = {
    name: editMode ? selectedSchedule?.name : '',
    description: editMode ? selectedSchedule?.description : '',
    type: editMode ? selectedSchedule?.data?.schedule_type : 'lan',
    schedule: editMode ? selectedSchedule?.ip_address : '',
    time: editMode
      ? parseSchedule(selectedSchedule)
      : {
          periodSelection: 'week',
          weekDays: [],
          allDay: false,
          startTime: '00:00',
          stopTime: '24:00',
          timezone: moment.tz.guess(),
        },
  };

  const validateForm = (values) => {
    const errors = {};

    if (!values.name || !values.name.trim()) {
      errors.name = intl.formatMessage({ id: 'GENERAL.REQUIRED_FIELD' });
    }

    if (values.time.weekDays.length === 0) {
      errors.time = {};
      errors.time.weekDays = intl.formatMessage({
        id: 'OBJECT_EXPLORER.WEEKDAYS_ERROR',
      });
    }

    if (
      !values.time.allDay &&
      isValidTime(values.time.startTime, values.time.stopTime)
    ) {
      if ('time' in errors === false) {
        errors.time = {};
      }
      errors.time.stopTime = intl.formatMessage({
        id: 'OBJECT_EXPLORER.TIME_ERROR',
      });
    }

    return errors;
  };

  /*const parseTime = (value) => {
    return value.split(':').map((p) => [parseInt(p)]);
  };*/

  return (
    <Modal show={showModal} onHide={onHide} centered size="md">
      <Modal.Header closeButton>
        <Modal.Title>
          {editMode ? (
            <FormattedMessage id="SCHEDULE_OBJECTS.EDIT" />
          ) : (
            <FormattedMessage id="SCHEDULE_OBJECTS.ADD" />
          )}
        </Modal.Title>
      </Modal.Header>

      <Formik
        initialValues={initialScheduleValues}
        onSubmit={(values, { setStatus, setSubmitting }) => {
          setSubmitting(true);

          let params = {
            name: values.name,
            schedule_type: values.type,
            ip_address: '',
            type: 'schedule',
            description: values.description,
            time_start: values.time.allDay ? '00:00' : values.time.startTime,
            time_stop: values.time.allDay ? '24:00' : values.time.stopTime,
            timezone: values.time.timezone,
            weekdays: values.time.weekDays.join(','),
          };

          if (editMode) {
            updateSchedule({ ...params, id: selectedSchedule.id })
              .then((response) => {
                onUpdate(params);
              })
              .catch((err) => {})
              .finally(() => setSubmitting(false));
          } else {
            createSchedule(params)
              .then((response) => {
                onCreate();
              })
              .catch((err) => {})
              .finally(() => setSubmitting(false));
          }
        }}
        validate={validateForm}
      >
        {({
          values,
          status,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Modal.Body>
              <Form.Group controlId="name">
                <Form.Label>
                  <FormattedMessage id="GENERAL.NAME" />
                </Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  onChange={handleChange}
                  isInvalid={touched.name && errors.name}
                  className="border-secondary"
                  placeholder={intl.formatMessage({
                    id: 'GENERAL.NAME_PLACEHOLDER',
                  })}
                  spellCheck="false"
                  value={values.name}
                  required
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="description">
                <Form.Label>
                  <FormattedMessage id="GENERAL.DESCRIPTION" />
                </Form.Label>
                <Form.Control
                  type="text"
                  name="description"
                  onChange={handleChange}
                  isInvalid={touched.description && errors.description}
                  className="border-secondary"
                  placeholder={intl.formatMessage({
                    id: 'GENERAL.DESCRIPTION_PLACEHOLDER',
                  })}
                  spellCheck="false"
                  value={values.description}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.description}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="time.weekDays">
                <Form.Label>
                  <FormattedMessage id="TIME.DAYS_OF_WEEK" />
                </Form.Label>
                <BeSafeWeekDaysRow
                  formField={values.time.weekDays}
                  onChange={handleChange}
                />
                {errors.time?.weekDays && touched.time?.weekDays && (
                  <BeSafeControlFeedback>
                    {errors.time.weekDays}
                  </BeSafeControlFeedback>
                )}
              </Form.Group>
              <Form.Group controlId="time.allDay">
                <Form.Label className="mr-2">
                  <FormattedMessage id="OBJECT_EXPLORER.ALL_DAY" />
                </Form.Label>
                <BeSafeSwitch
                  state={values.time.allDay}
                  name="time.allDay"
                  onChange={(val) => setFieldValue('time.allDay', val)}
                />
              </Form.Group>
              {!values.time.allDay && (
                <Row>
                  <Col sm={6}>
                    <Form.Group controlId="time.startTime">
                      <Form.Label>
                        <FormattedMessage id="OBJECT_EXPLORER.START_TIME" />
                      </Form.Label>
                      <BeSafeTimeRange
                        name="start"
                        value={values.time?.startTime || '00:00'}
                        handleChange={(value) => {
                          setFieldValue('time.startTime', value);
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col sm={6}>
                    <Form.Group controlId="time.stopTime">
                      <Form.Label>
                        <FormattedMessage id="OBJECT_EXPLORER.STOP_TIME" />
                      </Form.Label>
                      <BeSafeTimeRange
                        name="stop"
                        value={values.time?.stopTime || '24:00'}
                        handleChange={(value) => {
                          setFieldValue('time.stopTime', value);
                        }}
                      />
                      {errors.time?.stopTime && touched.time?.stopTime && (
                        <div className="w-100 text-danger">
                          {errors.time.stopTime}
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              )}
              <Form.Group controlId="time.timezone">
                <Form.Label>
                  <FormattedMessage id="TIME.TIMEZONE" />
                </Form.Label>
                <Form.Control
                  as="select"
                  value={values?.time?.timezone}
                  onChange={handleChange}
                  className="border-secondary"
                  custom
                >
                  {moment.tz.names().map((tz) => (
                    <option key={`tz-${tz.toLowerCase().replace('/', '-')}`}>
                      {tz}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer className="py-3">
              <BeSafeButton
                variant="primary"
                type="submit"
                disabled={isSubmitting}
                text={<FormattedMessage id="GENERAL.SAVE" />}
              />
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default ScheduleModal;
