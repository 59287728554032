import React, { useState } from "react";
import { FormControl } from "react-bootstrap";
import { useIntl } from "react-intl";
import SearchIcon from '@mui/icons-material/Search'
import ClearIcon from "@mui/icons-material/Clear";
import BeSafeButton from "components/commons/BeSafeButton";
import { WaitTime } from "utils/searchWaitTime";

const BeSafeSearchBar = ({onSearch, visible, placeholder}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(0);
  const intl = useIntl();

  const searchData = (event) => {
    var searchText = event.target.value;

    setSearchQuery(searchText);

    if (searchTimeout) clearTimeout(searchTimeout);

    let timeout = setTimeout(() => {
      onSearch(searchText);
    }, WaitTime);
    setSearchTimeout(timeout);
  };

  const clear = () => {
    setSearchQuery("");
    onSearch("");
  };

  return visible ? (
    <div className="d-flex align-items-center w-50">
      <SearchIcon />
      <FormControl
        type="text"
        placeholder={
          placeholder ??
          intl.formatMessage({
            id: "GENERAL.PLACEHOLDER_SEARCH",
          })
        }
        className="border-0 py-4 pl-2"
        value={searchQuery}
        onChange={(event) => searchData(event)}
      />
      <BeSafeButton
        visible={searchQuery !== ""}
        icon={<ClearIcon />}
        onClick={clear}
        variant="transparent"
      />
    </div>
  ) : null;
};

BeSafeSearchBar.defaultProps = {
  visible: true,
  onSearch: () => {},
};

export default BeSafeSearchBar;
