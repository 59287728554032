import React, { useState, useEffect } from 'react';
import { ButtonGroup, Form, ToggleButton } from 'react-bootstrap';
import { useIntl } from 'react-intl';

const BeSafeWeekDaysRow = ({ formField, onChange }) => {
  const intl = useIntl();

  const [firstDayOfWeek, setFirstDayOfWeek] = useState('sunday');

  const weekDays = [
    {
      name: 'sunday',
      code: 0,
      isWeekend: true,
    },
    {
      name: 'monday',
      code: 1,
      isWeekend: false,
    },
    {
      name: 'tuesday',
      code: 2,
      isWeekend: false,
    },
    {
      name: 'wednesday',
      code: 3,
      isWeekend: false,
    },
    {
      name: 'thursday',
      code: 4,
      isWeekend: false,
    },
    {
      name: 'friday',
      code: 5,
      isWeekend: false,
    },
    {
      name: 'saturday',
      code: 6,
      isWeekend: true,
    },
  ];

  const getWeekDays = () => {
    const newWeekDays = [...weekDays];

    if (firstDayOfWeek === 'Lunes') {
      const sunday = newWeekDays.shift();
      newWeekDays.push(sunday);
    }
    return newWeekDays;
  };

  useEffect(() => {
    setFirstDayOfWeek(intl.formatMessage({ id: 'TIME.FIRST_DAY_OF_WEEK' }));
  }, [intl]);

  return (
    <>
      <Form.Row style={{ justifyContent: 'center' }}>
        <ButtonGroup toggle className="mb-2 d-flex flex-wrap">
          {getWeekDays().map(({ name, code }) => (
            <ToggleButton
              key={`weekday-${name}`}
              className="flex-grow-0"
              id={`weekday-${name}`}
              type="checkbox"
              variant="outline-primary"
              name="time.weekDays"
              defaultChecked={false}
              checked={formField.includes(`${code}`)}
              value={code}
              onChange={onChange}
              size="sm"
            >
              {intl.formatMessage({
                id: `TIME.MEDIUM_${name.toUpperCase()}`,
              })}
            </ToggleButton>
          ))}
        </ButtonGroup>
      </Form.Row>
    </>
  );
};

export default BeSafeWeekDaysRow;
