import { useKeycloak } from '@react-keycloak/web';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useLogout from 'hooks/useLogout';
import InactivityModal from 'components/modals/InactivityModal';
import Router from 'shared/Router';
import axios from 'axios';
import cogoToast from 'cogo-toast';
import Loader from './Loader';
import { getCountNotificacionChanges } from 'app/crud/configuration.crud';
import { SetConfigurationChanges } from 'store/ducks/configuration.duck';

const AuthenticatedApp = () => {
  const { keycloak } = useKeycloak();
  const dispatch = useDispatch();
  const logout = useLogout();

  const { isAuthenticated } = useSelector(({ auth }) => ({
    isAuthenticated: !!auth.user,
  }));

  useEffect(() => {
    axios.interceptors.response.use(
      (response) => {
        if (
          response?.config?.method !== 'get' &&
          response?.config?.url !== 'sign_out'
        ) {
          getCountNotificacionChanges().then((response) => {
            dispatch(SetConfigurationChanges(response.data.count_changes));
          });
        }
        return Promise.resolve(response);
      },
      (error) => {
        if (error.message === 'Network Error') {
          cogoToast.error('API server not running', {
            heading: 'Error',
            bar: { size: '10px' },
            position: 'top-right',
          });
        } else if (!keycloak.authenticated || error.response.status === 401) {
          return logout();
        } else {
          return Promise.reject(error);
        }
      }
    );
  }, [dispatch, keycloak, logout]);

  if (!isAuthenticated) {
    return <Loader />;
  }

  return (
    <>
      <InactivityModal />
      <Router />
    </>
  );
};

export default AuthenticatedApp;
