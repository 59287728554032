import axios from 'axios';
import { createBrowserHistory } from 'history';

createBrowserHistory();

export const localGroup = 'Local Group';
export const publicGroup = 'Public Group';
export const LAN_GROUP = 'LanGroup';
export const NO_LAN_GROUP = 'NoLanGroup';

export const TCP_PORT = '601';
export const UDP_PORT = '514';
export const TLS_PORT = '6514';

export const PROFILE_ID_PREFIX = 'profId-';

export const APP_NAME = 'BeSafe Pro';

export const getCookie = (name) => {
  const re = new RegExp(name + '=([^;]+)');
  const value = re.exec(document.cookie);
  return value != null ? value[1] : null;
};

export const setCookie = (name, value, expires = '') => {
  return (document.cookie = name + ' =' + value + '; path=/;' + expires);
};

export function setupAxios(axios) {
  axios.defaults.baseURL =
    process.env.NODE_ENV === 'production'
      ? '/api'
      : process.env.REACT_APP_BESAFEPRO_API;

  axios.defaults.withCredentials = true;
  axios.defaults.headers.Accept = 'application/json';
  axios.defaults.headers.X_REQUESTED_WITH = 'XMLHttpRequest';
}

export const convertToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file);
    fileReader.onload = () => {
      resolve(fileReader.result);
    };
    fileReader.onerror = (error) => {
      reject(error);
    };
  });
};

export const isLanNetwork = (n) =>
  n?.['nat-settings']?.['hide-behind'] === 'gateway';

export const isNotLanNetwork = (n) =>
  n?.['nat-settings']?.['hide-behind'] === undefined;

export const isLanGroup = (g) => g.comments === LAN_GROUP;

export const isNotLanGroup = (g) => g.comments === NO_LAN_GROUP;

export const whereUsed = async (uid) => {
  let values = [];
  try {
    var response = await axios.get(`/miscs/${uid}/where_used_list`);
    var res = response.data;
    if (res.used_directly.length) values = [...res.used_directly];
    if (res.used_indirectly.length)
      values = [...values, ...res.used_indirectly];
    return values.length <= 0 ? [] : values.map((val) => val.name);
  } catch (err) {
    return [];
  }
};

export const isSpacePreset = (value) => {
  if (/\s/.test(value)) {
    return true;
  }
  return false;
};

export const isValidNumberInput = (value) => {
  return value || value === 0;
};

export const isAccessRoleSourceType = (value) => {
  return value === 'access-role';
};

export const isLocalIpaddress = (value) => {
  return /(((192\.168\.)|(172\.16\.))(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\/((1[6-9])|(2[0-9])|(3[0-2]))|(10\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?))\/([8-9]|(1[0-9])|(2[0-9])|(3[0-2])))$/.test(
    value
  );
};

export const isPublicIpaddress = (value) => {
  return /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\/((2[4-9])|(3[0-2]))$/.test(
    value
  );
};

export const isValidIp = (value) => {
  return /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
    value
  );
};

export const isValidIPSubnet = (value) => {
  return /([01]?\d\d?|2[0-4]\d|25[0-5])(?:\.(?:[01]?\d\d?|2[0-4]\d|25[0-5])){3}(?:\/[0-2]\d|\/3[0-2])?$/.test(
    value
  );
};

export const isValidDomainName = (value) => {
  return /^(?!www\.)[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value);
};

export function isValidEmail(email) {
  if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email)) {
    return true;
  }
  return false;
}

export function isPasswordValid(password) {
  if (/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$/i.test(password)) {
    return true;
  }

  return false;
}

export const isUserReadOnly = (auth) => {
  return auth.user !== null ? auth.user.is_read_only : false;
};

export const removeGroupNameUnderScore = (value) => {
  return value.replace(/^A_/, '');
};

export const isStartingWithAlphabet = (value) => {
  if (/^[a-zA-Z]+$/.test(value.charAt(0))) {
    return true;
  }
  return false;
};

export const convertToSnakeCase = (value) => {
  return value
    .split(/(?=[A-Z])/)
    .map((v) => v.toLowerCase())
    .join('_');
};

export const camelizeString = (value) => {
  return capitalize(
    value
      .toLowerCase()
      .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
  );
};

export const camelToSnakeCase = (str) => {
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
};

export const snakeToCamelCase = (str) => {
  return str.replace(/([-_]\w)/g, (g) => g[1].toUpperCase());
};

export const capitalize = (value) => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const convertToKebabCase = (value) => {
  return value
    .split(/(?=[A-Z])/)
    .map((v) => v.toLowerCase())
    .join('-');
};

export const decodeAscii = (asciiText) => {
  return asciiText
    .split(' ')
    .map((asciiChar) => String.fromCharCode(asciiChar))
    .join('');
};

export const stringToAscii = (string) => {
  return string
    .split('')
    .map((utf8Char) => utf8Char.charCodeAt(0))
    .join(' ');
};

export const isPortValid = (value) => {
  return (
    !Number.isNaN(value) && parseInt(value) >= 1 && parseInt(value) <= 65535
  );
};

export const isRangePortValid = (range) => {
  return isPortValid(range[0]) && isPortValid(range[1]);
};

export const isRangePortOrderValid = (range) => {
  return (
    !Number.isNaN(range[0]) &&
    !Number.isNaN(range[1]) &&
    parseInt(range[0]) < parseInt(range[1])
  );
};

export const isTokenExpired = (token) =>
  Date.now() >= JSON.parse(atob(token.split('.')[1])).exp * 1000;

export const isValidHostname = (value) => {
  if (typeof value !== 'string') return false;

  const validHostnameChars = /^[a-zA-Z0-9-.]{1,253}\.?$/g;
  if (!validHostnameChars.test(value)) {
    return false;
  }

  if (value.endsWith('.')) {
    value = value.slice(0, value.length - 1);
  }

  if (value.length > 253) {
    return false;
  }

  const labels = value.split('.');

  const isValid = labels.every(function (label) {
    const validLabelChars = /^([a-zA-Z0-9-]+)$/g;

    const validLabel =
      validLabelChars.test(label) &&
      label.length < 64 &&
      !label.startsWith('-') &&
      !label.endsWith('-');

    return validLabel;
  });

  return isValid;
};

export const networkObjectDatas = {
  network: {
    text: 'OBJECT_EXPLORER.NETWORK',
  },
  network_range: {
    text: 'NETWORK_OBJECTS.RANGE',
  },
  mac: {
    text: 'NETWORK_OBJECTS.MAC_ADDRESS',
  },
  fqdn: {
    text: 'OBJECT_EXPLORER.FQDN',
  },
  geolocation: {
    text: 'OBJECT_EXPLORER.GEOLOCATION',
  },
  user: {
    text: 'OBJECT_EXPLORER.USERS',
  },
  casb_tenant: {
    text: 'OBJECT_EXPLORER.CASB_TENANTS',
  },
  'network-group': {
    text: 'OBJECT_EXPLORER.NETWORK_GROUP',
  },
  'network_range-group': {
    text: 'NETWORK_OBJECTS.RANGE_GROUP',
  },
  'mac-group': {
    text: 'NETWORK_OBJECTS.MAC_ADDRESS_GROUP',
  },
  'fqdn-group': {
    text: 'OBJECT_EXPLORER.FQDN_GROUP',
  },
  'geolocation-group': {
    text: 'OBJECT_EXPLORER.GEOLOCATION_GROUP',
  },
  'user-group': {
    text: 'OBJECT_EXPLORER.USERS_GROUP',
  },
  'vpn-user': {
    text: 'VPN_USERS.TITLE',
  },
  'vpn-user-group': {
    text: 'VPN_USER_GROUPS.TITLE',
  },
  dlp_file_type: {
    text: 'DLP.FILE_TYPES',
  },
  'dlp_file_type-group': {
    text: 'DLP.FILE_TYPE_GROUPS',
  },
  dlp_file_name: {
    text: 'DLP.FILE_NAMES',
  },
  'dlp_file_name-group': {
    text: 'DLP.FILE_NAME_GROUPS',
  },
  dlp_content: {
    text: 'DLP.CONTENT',
  },
  'dlp_content-group': {
    text: 'DLP.CONTENT_GROUPS',
  },
};

export const serviceObjectDatas = {
  tcp_service: {
    text: 'OBJECT_EXPLORER.TCP_SERVICE',
  },
  udp_service: {
    text: 'OBJECT_EXPLORER.UDP_SERVICE',
  },
  icmp_service: {
    text: 'OBJECT_EXPLORER.ICMP_SERVICE',
  },
  other_service: {
    text: 'OBJECT_EXPLORER.OTHER_SERVICE',
  },
  'tcp_service-group': {
    text: 'OBJECT_EXPLORER.TCP_SERVICE_GROUP',
  },
  'udp_service-group': {
    text: 'OBJECT_EXPLORER.UDP_SERVICE_GROUP',
  },
  'icmp_service-group': {
    text: 'OBJECT_EXPLORER.ICMP_SERVICE_GROUP',
  },
  'other_service-group': {
    text: 'OBJECT_EXPLORER.OTHER_SERVICE_GROUP',
  },
};

export const recordObjectDatas = {
  dns_record: {
    text: 'OBJECT_EXPLORER.DNS_RECORD',
  },
};

export const objectDatas = {
  network: {
    text: 'OBJECT_EXPLORER.NETWORK_OBJECTS',
    icon: 'fas fa-network-wired',
  },
  group: {
    text: 'OBJECT_EXPLORER.NETWORK_GROUPS',
    icon: 'fas fa-project-diagram',
  },
  'access-role': {
    text: 'OBJECT_EXPLORER.ACCESS_ROLES',
    icon: 'fas fa-users',
  },
  'service-tcp': {
    text: 'SERVICES.TCP_SERVICE',
    icon: 'fas fa-exchange-alt',
  },
  'service-udp': {
    text: 'SERVICES.UDP_SERVICE',
    icon: 'fas fa-exchange-alt',
  },
  'service-icmp': {
    text: 'SERVICES.ICMP_SERVICE',
    icon: 'fas fa-exchange-alt',
  },
  'service-other': {
    text: 'SERVICES.OTHER_SERVICE',
    icon: 'fas fa-exchange-alt',
  },
  'application-site': {
    text: 'OBJECT_EXPLORER.APPLICATIONS_DEFAULT',
    icon: 'fas fa-cubes',
  },
  'application-site-custom': {
    text: 'OBJECT_EXPLORER.APPLICATIONS_CUSTOM',
    icon: 'fas fa-cubes',
    comment: '_(is_custom)_',
  },
  'application-site-category': {
    text: 'OBJECT_EXPLORER.CATEGORIES',
    icon: 'fas fa-shapes',
  },
  'dns-domain': {
    text: 'OBJECT_EXPLORER.DOMAIN',
    icon: 'fas fa-globe',
  },
  users: {
    text: 'USER_GROUPS.GROUP',
    icon: 'far fa-user',
  },
  'user-group': {
    text: 'USER_GROUPS.GROUP',
    icon: 'fas fa-users',
  },
  'access-role-with-group': {
    text: 'USER_GROUPS.GROUP',
    icon: 'fas fa-users',
  },
  'threat-protection': {
    text: 'THREAT_PREVENTION.THREAT_PROTECTIONS',
    icon: 'fas fa-shield-virus',
  },
  CpmiSdTopicPerProfileDynamic: {
    icon: 'fas fa-shield-virus',
  },
  pattern: {
    text: 'PATTERNS.URL',
    icon: 'fas fa-spell-check',
  },
  CpmiPatternDataType: {
    text: 'PATTERNS.URL',
    icon: 'fas fa-spell-check',
  },
  default: {
    icon: 'far fa-circle',
  },
  'simple-gateway': {
    icon: 'fas fa-hdd',
  },
  'simple-cluster': {
    icon: 'fas fa-server',
  },
  'vpn-user': {
    text: 'VPN_USERS.OBJECT_TITLE',
    icon: 'fas fa-users',
  },
};

export const isValidTime = (startTime, stopTime) => {
  let startTimeVal = parseInt(startTime.split(':').join(''));
  let stopTimeVal = parseInt(stopTime.split(':').join(''));

  return startTimeVal >= stopTimeVal;
};

export const getExtension = (filename) => {
  return filename?.split('.').pop().toLowerCase();
};

export const checkPort = (port, protocol, mutual_tls, setFieldValue) => {
  if (
    port.length === 0 &&
    typeof setFieldValue === 'function' &&
    setFieldValue
  ) {
    if (mutual_tls) {
      setFieldValue('port', TLS_PORT);
    } else if (protocol === 'tcp') {
      setFieldValue('port', TCP_PORT);
    } else if (protocol === 'udp') {
      setFieldValue('port', UDP_PORT);
    }
  }
};

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 *
 * @link https://stackoverflow.com/questions/10420352/converting-file-size-in-bytes-to-human-readable-string
 */
export const humanFileSize = (bytes, si = false, dp = 1) => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  );

  return bytes.toFixed(dp) + ' ' + units[u];
};

// Necessary memory in megabytes
export const necessaryMemoryByModel = {
  vm: {
    standard: 310,
    extended: 500,
    extreme: 2048,
  },
  other: {
    standard: 450,
    extended: 1024,
    extreme: 3092,
  },
};

export const memoryByModel = {
  RS420: 1024,
  M10: 2048,
  M2: 2048,
  ARES: 8192,
};

export const getNecessaryMemory = (model, rulePack, oldRulePack) => {
  return (
    necessaryMemoryByModel[model === 'VM' ? 'vm' : 'other'][rulePack] -
    necessaryMemoryByModel[model === 'VM' ? 'vm' : 'other'][oldRulePack]
  );
};

export const getObjectId = (object) => {
  return object?.[0] ? object[0].id : null;
};

export const getSelectedObjects = (objects, field) => {
  return objects[0]?.[field] ? [objects[0]?.[field]] : [];
};

export const filterDnsRecords = (records, search_protection_config) => {
  if (
    search_protection_config === null ||
    search_protection_config.search_protection === false
  ) {
    return records.filter((record) => record.data === null);
  }

  return records.filter(
    (record) =>
      record.data === null ||
      !('youtube_mode' in record.data) ||
      record.data.youtube_mode === search_protection_config?.youtube_mode
  );
};

export const onlyUnique = (value, index, array) => {
  return array.indexOf(value) === index;
};

export const removeDuplicated = (array) => {
  return array.filter(onlyUnique);
};

export const getWhereUsed = (selectedNetwork) => {
  let whereUsed = [];

  if (selectedNetwork?.firewall_rules) {
    for (let obj of selectedNetwork.firewall_rules) {
      whereUsed.push(obj.name);
    }
  }

  if (selectedNetwork?.vpn_tunnels) {
    for (let obj of selectedNetwork.vpn_tunnels) {
      whereUsed.push(obj.name);
    }
  }

  if (selectedNetwork?.dns_fw_1_configs) {
    for (let obj of selectedNetwork.dns_fw_1_configs) {
      whereUsed.push(obj.device?.alias_name);
    }
  }

  if (selectedNetwork?.dns_fw_2_configs) {
    for (let obj of selectedNetwork.dns_fw_2_configs) {
      whereUsed.push(obj.device?.alias_name);
    }
  }

  if (selectedNetwork?.dns_rslv_1_configs) {
    for (let obj of selectedNetwork.dns_rslv_1_configs) {
      whereUsed.push(obj.device?.alias_name);
    }
  }

  if (selectedNetwork?.dns_rslv_2_configs) {
    for (let obj of selectedNetwork.dns_rslv_2_configs) {
      whereUsed.push(obj.device?.alias_name);
    }
  }

  if (selectedNetwork?.dns_configs) {
    for (let obj of selectedNetwork.dns_configs) {
      whereUsed.push(obj.device?.alias_name);
    }
  }

  return removeDuplicated(whereUsed);
};

export const getTenantType = (tenant_type) => {
  if (tenant_type === 'reseller_admin') {
    return 'Reseller';
  }
  return 'End customer';
};

export const getTenantName = (tenants, id) => {
  const tenant = tenants.find((tenant) => tenant.id === id);
  const tenantName = tenant?.name || '';
  return tenantName;
};

export const encodeToBase64 = (data) => {
  return btoa(data);
};

export const decodeToBase64 = (encodedData) => {
  return atob(encodedData);
};

export const encodeProfileId = (id) => {
  return encodeToBase64(PROFILE_ID_PREFIX + id);
};

export const decodeProfileId = (encodedId) => {
  const decodedId = decodeToBase64(encodedId);

  if (decodedId.startsWith(PROFILE_ID_PREFIX)) {
    return decodedId.substring(PROFILE_ID_PREFIX.length);
  }

  return decodedId;
};

export const getLicenseInfo = (licenseType) => {
  const licenseGroup = licenseType.slice(0, licenseType.lastIndexOf('_'));
  const licenseName = licenseType.slice(licenseType.lastIndexOf('_') + 1);

  return {
    licenseGroup,
    licenseName,
  };
};

export const getFunctionalityParts = (licenseName = '') => {
  const elemParts = licenseName.split('_');
  const functionality = elemParts.pop();

  return [functionality, elemParts.join('_')];
};

export const formatLicenseName = (license, intl) => {
  const typeParts = getFunctionalityParts(license.name);

  const translatedParts = typeParts.map((part) =>
    intl.formatMessage({ id: 'LICENSE_TYPES.' + part })
  );

  const licenseName = translatedParts.join(' ');

  if (isUserBasedLicense(license)) {
    return intl.formatMessage(
      { id: 'LICENSE_TYPES.USER_BASED' },
      { license: licenseName }
    );
  }

  return licenseName;
};

export const formatLicenseType = (licenseType, intl) => {
  const { licenseGroup, licenseName } = getLicenseInfo(licenseType);

  const licenseGroupText = intl.formatMessage({
    id: `LICENSE_TYPES.${licenseGroup}`,
  });

  const licenseNameText = intl.formatMessage({
    id: `LICENSE_TYPES.${licenseName}`,
  });

  return `${licenseGroupText}: ${licenseNameText}`;
};

export const getLicenseDisplayName = (license) => {
  const licenseType = license.name;
  const { licenseName } = getLicenseInfo(licenseType);

  if (isUserBasedLicense(license)) {
    return `SSE_${licenseName}`;
  }

  return licenseType;
};

export const validateIpsOtWithoutIpsLicense = (licensesTypes) => {
  const typeParts = licensesTypes.map((type) => getFunctionalityParts(type));

  const hasMatchingType = typeParts.some(
    (part) =>
      part[0] === 'IPSOT' &&
      licensesTypes.includes(`${part[1]}_IPSOT`) &&
      !licensesTypes.includes(`${part[1]}_IPS`)
  );

  return hasMatchingType;
};

export const filterByCloudLicenses = (licenses) => {
  return licenses.filter((license) => !license.name.startsWith('UTM_'));
};

export const filterByUtmLicenses = (licenses) => {
  return licenses.filter((license) => license.name.startsWith('UTM_'));
};

export const isUserBasedLicense = (license) => {
  return license.max_count === 0 && 'sse_users' in license.data;
};

export const calculateLicenseNumber = (license) => {
  if (isUserBasedLicense(license)) {
    return license.data.sse_users;
  }
  return license.max_count;
};

export const removeAssignedLicenses = (licenses) => {
  return licenses.filter(
    (license) => license.assigned < calculateLicenseNumber(license)
  );
};

export const isValidUrl = (url) => {
  try {
    new URL(url);
    return true;
  } catch (e) {
    return false;
  }
};

export const isValidIPAddressOrSubnet = (value) => {
  // Regular expression to match a valid IP address
  const ipRegex =
    /^(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}$/;

  // Regular expression to match a valid subnet mask (0-32)
  const subnetRegex = /^\/(3[0-2]|[1-2]?[0-9])$/;

  // Check if the input is a valid IP address
  if (ipRegex.test(value)) {
    return true;
  }

  // Check if the input is a valid IP address with a valid subnet mask
  const parts = value.split('/');
  if (
    parts.length === 2 &&
    ipRegex.test(parts[0]) &&
    subnetRegex.test('/' + parts[1])
  ) {
    // Additional check to ensure the IP address is a network address if a subnet mask is provided
    const ipParts = parts[0].split('.').map(Number);
    const subnet = parseInt(parts[1], 10);
    const hostBits = 32 - subnet;
    const hostMask = (1 << hostBits) - 1;
    const ipAsInt =
      (ipParts[0] << 24) | (ipParts[1] << 16) | (ipParts[2] << 8) | ipParts[3];
    if ((ipAsInt & hostMask) === 0) {
      return true;
    }
  }

  return false;
};
