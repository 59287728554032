const SUPPORTED_SHORTCUTS = [
  {
    name: '@yearly',
    value: '0 0 1 1 *',
  },
  {
    name: '@annually',
    value: '0 0 1 1 *',
  },
  {
    name: '@monthly',
    value: '0 0 1 * *',
  },
  {
    name: '@weekly',
    value: '0 0 * * 0',
  },
  {
    name: '@daily',
    value: '0 0 * * *',
  },
  {
    name: '@midnight',
    value: '0 0 * * *',
  },
  {
    name: '@hourly',
    value: '0 * * * *',
  },
];

const UNITS = [
  {
    type: 'minutes',
    min: 0,
    max: 59,
    total: 60,
  },
  {
    type: 'hours',
    min: 0,
    max: 24,
    total: 24,
  },
  {
    type: 'month-days',
    min: 1,
    max: 31,
    total: 31,
  },
  {
    type: 'months',
    min: 1,
    max: 12,
    total: 12,
    alt: [
      'JAN',
      'FEB',
      'MAR',
      'APR',
      'MAY',
      'JUN',
      'JUL',
      'AUG',
      'SEP',
      'OCT',
      'NOV',
      'DEC',
    ],
  },
  {
    type: 'week-days',
    min: 0,
    max: 6,
    total: 7,
    alt: ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'],
  },
];

export const TIMEZONES = [
  { name: 'International Date Line West', value: 'Etc/GMT+12' },
  { name: 'Midway Island', value: 'Pacific/Midway' },
  { name: 'American Samoa', value: 'Pacific/Pago_Pago' },
  { name: 'Hawaii', value: 'Pacific/Honolulu' },
  { name: 'Alaska', value: 'America/Juneau' },
  { name: 'Pacific Time (US & Canada)', value: 'America/Los_Angeles' },
  { name: 'Tijuana', value: 'America/Tijuana' },
  { name: 'Mountain Time (US & Canada)', value: 'America/Denver' },
  { name: 'Arizona', value: 'America/Phoenix' },
  { name: 'Chihuahua', value: 'America/Chihuahua' },
  { name: 'Mazatlan', value: 'America/Mazatlan' },
  { name: 'Central Time (US & Canada)', value: 'America/Chicago' },
  { name: 'Saskatchewan', value: 'America/Regina' },
  { name: 'Mexico City, Guadalajara', value: 'America/Mexico_City' },
  { name: 'Monterrey', value: 'America/Monterrey' },
  { name: 'Central America', value: 'America/Guatemala' },
  { name: 'Eastern Time (US & Canada)', value: 'America/New_York' },
  { name: 'Indiana (East)', value: 'America/Indiana/Indianapolis' },
  { name: 'Bogota', value: 'America/Bogota' },
  { name: 'Lima, Quito', value: 'America/Lima' },
  { name: 'Atlantic Time (Canada)', value: 'America/Halifax' },
  { name: 'Caracas', value: 'America/Caracas' },
  { name: 'La Paz', value: 'America/La_Paz' },
  { name: 'Santiago', value: 'America/Santiago' },
  { name: 'Newfoundland', value: 'America/St_Johns' },
  { name: 'Brasilia', value: 'America/Sao_Paulo' },
  { name: 'Buenos Aires', value: 'America/Argentina/Buenos_Aires' },
  { name: 'Montevideo', value: 'America/Montevideo' },
  { name: 'Georgetown', value: 'America/Guyana' },
  { name: 'Puerto Rico', value: 'America/Puerto_Rico' },
  { name: 'Greenland', value: 'America/Godthab' },
  { name: 'Mid-Atlantic', value: 'Atlantic/South_Georgia' },
  { name: 'Azores', value: 'Atlantic/Azores' },
  { name: 'Cape Verde Is.', value: 'Atlantic/Cape_Verde' },
  { name: 'Dublin', value: 'Europe/Dublin' },
  { name: 'Lisbon', value: 'Europe/Lisbon' },
  { name: 'London, Edinburgh', value: 'Europe/London' },
  { name: 'Casablanca', value: 'Africa/Casablanca' },
  { name: 'Monrovia', value: 'Africa/Monrovia' },
  { name: 'UTC', value: 'Etc/UTC' },
  { name: 'Belgrade', value: 'Europe/Belgrade' },
  { name: 'Bratislava', value: 'Europe/Bratislava' },
  { name: 'Budapest', value: 'Europe/Budapest' },
  { name: 'Ljubljana', value: 'Europe/Ljubljana' },
  { name: 'Prague', value: 'Europe/Prague' },
  { name: 'Sarajevo', value: 'Europe/Sarajevo' },
  { name: 'Skopje', value: 'Europe/Skopje' },
  { name: 'Warsaw', value: 'Europe/Warsaw' },
  { name: 'Zagreb', value: 'Europe/Zagreb' },
  { name: 'Brussels', value: 'Europe/Brussels' },
  { name: 'Copenhagen', value: 'Europe/Copenhagen' },
  { name: 'Madrid', value: 'Europe/Madrid' },
  { name: 'Paris', value: 'Europe/Paris' },
  { name: 'Amsterdam', value: 'Europe/Amsterdam' },
  { name: 'Berlin', value: 'Europe/Berlin' },
  { name: 'Zurich, Bern', value: 'Europe/Zurich' },
  { name: 'Rome', value: 'Europe/Rome' },
  { name: 'Stockholm', value: 'Europe/Stockholm' },
  { name: 'Vienna', value: 'Europe/Vienna' },
  { name: 'West Central Africa', value: 'Africa/Algiers' },
  { name: 'Bucharest', value: 'Europe/Bucharest' },
  { name: 'Cairo', value: 'Africa/Cairo' },
  { name: 'Helsinki', value: 'Europe/Helsinki' },
  { name: 'Kyiv', value: 'Europe/Kiev' },
  { name: 'Riga', value: 'Europe/Riga' },
  { name: 'Sofia', value: 'Europe/Sofia' },
  { name: 'Tallinn', value: 'Europe/Tallinn' },
  { name: 'Vilnius', value: 'Europe/Vilnius' },
  { name: 'Athens', value: 'Europe/Athens' },
  { name: 'Istanbul', value: 'Europe/Istanbul' },
  { name: 'Minsk', value: 'Europe/Minsk' },
  { name: 'Jerusalem', value: 'Asia/Jerusalem' },
  { name: 'Harare', value: 'Africa/Harare' },
  { name: 'Pretoria', value: 'Africa/Johannesburg' },
  { name: 'Kaliningrad', value: 'Europe/Kaliningrad' },
  { name: 'Moscow, St. Petersburg', value: 'Europe/Moscow' },
  { name: 'Volgograd', value: 'Europe/Volgograd' },
  { name: 'Samara', value: 'Europe/Samara' },
  { name: 'Kuwait', value: 'Asia/Kuwait' },
  { name: 'Riyadh', value: 'Asia/Riyadh' },
  { name: 'Nairobi', value: 'Africa/Nairobi' },
  { name: 'Baghdad', value: 'Asia/Baghdad' },
  { name: 'Tehran', value: 'Asia/Tehran' },
  { name: 'Muscat, Abu Dhabi', value: 'Asia/Muscat' },
  { name: 'Baku', value: 'Asia/Baku' },
  { name: 'Tbilisi', value: 'Asia/Tbilisi' },
  { name: 'Yerevan', value: 'Asia/Yerevan' },
  { name: 'Kabul', value: 'Asia/Kabul' },
  { name: 'Ekaterinburg', value: 'Asia/Yekaterinburg' },
  { name: 'Karachi, Islamabad', value: 'Asia/Karachi' },
  { name: 'Tashkent', value: 'Asia/Tashkent' },
  { name: 'Kolkata, Chennai, Mumbai, New Delhi', value: 'Asia/Kolkata' },
  { name: 'Kathmandu', value: 'Asia/Kathmandu' },
  { name: 'Dhaka, Astana', value: 'Asia/Dhaka' },
  { name: 'Sri Jayawardenepura', value: 'Asia/Colombo' },
  { name: 'Almaty', value: 'Asia/Almaty' },
  { name: 'Novosibirsk', value: 'Asia/Novosibirsk' },
  { name: 'Rangoon', value: 'Asia/Rangoon' },
  { name: 'Bangkok', value: 'Asia/Bangkok' },
  { name: 'Hanoi', value: 'Asia/Bangkok' },
  { name: 'Jakarta', value: 'Asia/Jakarta' },
  { name: 'Krasnoyarsk', value: 'Asia/Krasnoyarsk' },
  { name: 'Beijing', value: 'Asia/Shanghai' },
  { name: 'Chongqing', value: 'Asia/Chongqing' },
  { name: 'Hong Kong', value: 'Asia/Hong_Kong' },
  { name: 'Urumqi', value: 'Asia/Urumqi' },
  { name: 'Kuala Lumpur', value: 'Asia/Kuala_Lumpur' },
  { name: 'Singapore', value: 'Asia/Singapore' },
  { name: 'Taipei', value: 'Asia/Taipei' },
  { name: 'Perth', value: 'Australia/Perth' },
  { name: 'Irkutsk', value: 'Asia/Irkutsk' },
  { name: 'Ulaanbaatar', value: 'Asia/Ulaanbaatar' },
  { name: 'Seoul', value: 'Asia/Seoul' },
  { name: 'Tokyo, Osaka, Sapporo', value: 'Asia/Tokyo' },
  { name: 'Yakutsk', value: 'Asia/Yakutsk' },
  { name: 'Darwin', value: 'Australia/Darwin' },
  { name: 'Adelaide', value: 'Australia/Adelaide' },
  { name: 'Melbourne, Canberra', value: 'Australia/Melbourne' },
  { name: 'Sydney', value: 'Australia/Sydney' },
  { name: 'Brisbane', value: 'Australia/Brisbane' },
  { name: 'Hobart', value: 'Australia/Hobart' },
  { name: 'Vladivostok', value: 'Asia/Vladivostok' },
  { name: 'Guam', value: 'Pacific/Guam' },
  { name: 'Port Moresby', value: 'Pacific/Port_Moresby' },
  { name: 'Magadan', value: 'Asia/Magadan' },
  { name: 'Srednekolymsk', value: 'Asia/Srednekolymsk' },
  { name: 'Solomon Is.', value: 'Pacific/Guadalcanal' },
  { name: 'New Caledonia', value: 'Pacific/Noumea' },
  { name: 'Fiji', value: 'Pacific/Fiji' },
  { name: 'Kamchatka', value: 'Asia/Kamchatka' },
  { name: 'Marshall Is.', value: 'Pacific/Majuro' },
  { name: 'Auckland, Wellington', value: 'Pacific/Auckland' },
  { name: "Nuku'alofa", value: 'Pacific/Tongatapu' },
  { name: 'Tokelau Is.', value: 'Pacific/Fakaofo' },
  { name: 'Chatham Is.', value: 'Pacific/Chatham' },
  { name: 'Samoa', value: 'Pacific/Apia' },
];

export function getValuesFromCronString(cronString, shortcuts) {
  let result = {
    period: 0,
    minutes: 0,
    hours: 0,
    monthDays: 0,
    months: 0,
    weekdays: 0,
  };
  let error = false;

  if (!cronString) {
    error = true;
  }

  if (!error) {
    if (shortcuts && (shortcuts === true || shortcuts.includes(cronString))) {
      const shortcutObject = SUPPORTED_SHORTCUTS.find(
        (supportedShortcut) => supportedShortcut.name === cronString
      );

      if (shortcutObject) {
        cronString = shortcutObject.value;
      }
    }

    try {
      const cronParts = parseCronString(cronString);
      const period = getPeriodFromCronparts(cronParts);

      result.period = period;
      result.minutes = cronParts[0];
      result.hours = cronParts[1];
      result.monthDays = cronParts[2];
      result.months = cronParts[3];
      result.weekDays =
        cronParts[4].length === 0 ? [0, 1, 2, 3, 4, 5, 6] : cronParts[4];
    } catch (err) {
      error = true;
    }
  }

  return result;
}

function parseCronString(str) {
  if (typeof str !== 'string') {
    throw new Error('Invalid cron string');
  }

  const parts = str.replace(/\s+/g, ' ').trim().split(' ');

  if (parts.length === 5) {
    return parts.map((partStr, idx) => {
      return parsePartString(partStr, UNITS[idx]);
    });
  }

  throw new Error('Invalid cron string format');
}

function getPeriodFromCronparts(cronParts) {
  if (cronParts[3].length > 0) {
    return 'year';
  } else if (cronParts[2].length > 0) {
    return 'month';
  } else if (cronParts[4].length > 0) {
    return 'week';
  } else if (cronParts[1].length > 0) {
    return 'day';
  } else if (cronParts[0].length > 0) {
    return 'hour';
  }
  return 'minute';
}

function parsePartString(str, unit) {
  if (str === '*' || str === '*/1') {
    return [];
  }

  const stringParts = str.split('/');

  if (stringParts.length > 2) {
    throw new Error(`Invalid value "${unit.type}"`);
  }

  const rangeString = replaceAlternatives(stringParts[0], unit.min, unit.alt);
  let parsedValues;

  if (rangeString === '*') {
    parsedValues = range(unit.min, unit.max);
  } else {
    parsedValues = sort(
      dedup(
        fixSunday(
          rangeString
            .split(',')
            .map((range) => {
              return parseRange(range, str, unit);
            })
            .flat(),
          unit
        )
      )
    );

    const value = outOfRange(parsedValues, unit);

    if (typeof value !== 'undefined') {
      throw new Error(`Value "${value}" out of range for ${unit.type}`);
    }
  }

  const step = parseStep(stringParts[1], unit);
  const intervalValues = applyInterval(parsedValues, step);

  if (intervalValues.length === unit.total) {
    return [];
  } else if (intervalValues.length === 0) {
    throw new Error(`Empty interval value "${str}" for ${unit.type}`);
  }

  return intervalValues;
}

function replaceAlternatives(str, min, alt) {
  if (alt) {
    str = str.toUpperCase();

    for (let i = 0; i < alt.length; i++) {
      str = str.replace(alt[i], `${i + min}`);
    }
  }
  return str;
}

export function range(start, end) {
  const array = [];

  for (let i = start; i <= end; i++) {
    array.push(i);
  }

  return array;
}

function parseRange(rangeStr, context, unit) {
  const subparts = rangeStr.split('-');

  if (subparts.length === 1) {
    const value = parseInt(subparts[0], 10);

    if (isNaN(value)) {
      throw new Error(`Invalid value "${context}" for ${unit.type}`);
    }

    return [value];
  } else if (subparts.length === 2) {
    const minValue = parseInt(subparts[0], 10);
    const maxValue = parseInt(subparts[1], 10);

    if (maxValue < minValue) {
      throw new Error(
        `Max range is less than min range in "${rangeStr}" for ${unit.type}`
      );
    }

    return range(minValue, maxValue);
  } else {
    throw new Error(`Invalid value "${rangeStr}" for ${unit.type}`);
  }
}

function parseStep(step, unit) {
  if (typeof step !== 'undefined') {
    const parsedStep = parseInt(step, 10);

    if (isNaN(parsedStep) || parsedStep < 1) {
      throw new Error(`Invalid interval step value "${step}" for ${unit.type}`);
    }

    return parsedStep;
  }
}

function applyInterval(values, step) {
  if (step) {
    const minVal = values[0];

    values = values.filter((value) => {
      return value % step === minVal % step || value === minVal;
    });
  }

  return values;
}

export function setError(onError, locale) {
  onError &&
    onError({
      type: 'invalid_cron',
      description: locale.errorInvalidCron,
    });
}

function sort(array) {
  array.sort(function (a, b) {
    return a - b;
  });

  return array;
}

function dedup(array) {
  const result = [];

  array.forEach(function (i) {
    if (result.indexOf(i) < 0) {
      result.push(i);
    }
  });

  return result;
}

export function getCronStringFromValues(
  period,
  months,
  monthDays,
  weekDays,
  hours,
  minutes,
  humanizeValue
) {
  if (period === 'reboot') {
    return '@reboot';
  }

  const newMonths = period === 'year' && months ? months : [];
  const newMonthDays =
    (period === 'year' || period === 'month') && monthDays ? monthDays : [];
  const newWeekDays =
    (period === 'year' || period === 'month' || period === 'week') && weekDays
      ? weekDays
      : [];
  const newHours =
    period !== 'minute' && period !== 'hour' && hours ? hours : [];
  const newMinutes = period !== 'minute' && minutes ? minutes : [];

  const parsedArray = parseCronArray(
    [newMinutes, newHours, newMonthDays, newMonths, newWeekDays],
    humanizeValue
  );

  return cronToString(parsedArray);
}

export function partToString(
  cronPart,
  unit,
  humanize,
  leadingZero,
  clockFormat
) {
  let retval = '';

  if (isFull(cronPart, unit) || cronPart.length === 0) {
    retval = '*';
  } else {
    const step = getStep(cronPart);

    if (step && isInterval(cronPart, step)) {
      if (isFullInterval(cronPart, unit, step)) {
        retval = `*/${step}`;
      } else {
        retval = `${formatValue(
          getMin(cronPart),
          unit,
          humanize,
          leadingZero,
          clockFormat
        )}-${formatValue(
          getMax(cronPart),
          unit,
          humanize,
          leadingZero,
          clockFormat
        )}/${step}`;
      }
    } else {
      retval = toRanges(cronPart)
        .map((range) => {
          if (Array.isArray(range)) {
            return `${formatValue(
              range[0],
              unit,
              humanize,
              leadingZero,
              clockFormat
            )}-${formatValue(
              range[1],
              unit,
              humanize,
              leadingZero,
              clockFormat
            )}`;
          }

          return formatValue(range, unit, humanize, leadingZero, clockFormat);
        })
        .join(',');
    }
  }
  return retval;
}

export function formatValue(value, unit, humanize, leadingZero, clockFormat) {
  let cronPartString = value.toString();
  const { type, alt, min } = unit;
  const needLeadingZero =
    leadingZero && (leadingZero === true || leadingZero.includes(type));
  const need24HourClock =
    clockFormat === '24-hour-clock' && (type === 'hours' || type === 'minutes');

  if ((humanize && type === 'week-days') || (humanize && type === 'months')) {
    cronPartString = alt[value - min];
  } else if (value < 10 && (needLeadingZero || need24HourClock)) {
    cronPartString = cronPartString.padStart(2, '0');
  }

  if (type === 'hours' && clockFormat === '12-hour-clock') {
    const suffix = value >= 12 ? 'PM' : 'AM';
    let hour = value % 12 || 12;

    if (hour < 10 && needLeadingZero) {
      hour = hour.toString().padStart(2, '0');
    }

    cronPartString = `${hour}${suffix}`;
  }

  return cronPartString;
}

function parseCronArray(cronArr, humanizeValue) {
  if (cronArr.length === 5) {
    return cronArr.map((partArr, idx) => {
      const unit = UNITS[idx];
      const parsedArray = parsePartArray(partArr, unit);

      return partToString(parsedArray, unit, humanizeValue);
    });
  }

  throw new Error('Invalid cron array');
}

function cronToString(parts) {
  return parts.join(' ');
}

function fixSunday(values, unit) {
  if (unit.type === 'week-days') {
    values = values.map(function (value) {
      if (value === 7) {
        return 0;
      }

      return value;
    });
  }

  return values;
}

function outOfRange(values, unit) {
  const first = values[0];
  const last = values[values.length - 1];

  if (first < unit.min) {
    return first;
  } else if (last > unit.max) {
    return last;
  }

  return;
}

function parsePartArray(arr, unit) {
  const values = sort(dedup(fixSunday(arr, unit)));

  if (values.length === 0) {
    return values;
  }

  const value = outOfRange(values, unit);

  if (typeof value !== 'undefined') {
    throw new Error(`Value "${value}" out of range for ${unit.type}`);
  }

  return values;
}

function isFull(values, unit) {
  return values.length === unit.max - unit.min + 1;
}

function getStep(values) {
  if (values.length > 2) {
    const step = values[1] - values[0];

    if (step > 1) {
      return step;
    }
  }
}

function isInterval(values, step) {
  for (let i = 1; i < values.length; i++) {
    const prev = values[i - 1];
    const value = values[i];

    if (value - prev !== step) {
      return false;
    }
  }

  return true;
}

function isFullInterval(values, unit, step) {
  const min = getMin(values);
  const max = getMax(values);
  const haveAllValues = values.length === (max - min) / step + 1;

  if (min === unit.min && max + step > unit.max && haveAllValues) {
    return true;
  }

  return false;
}

function getMin(values) {
  return values[0];
}

function getMax(values) {
  return values[values.length - 1];
}

function toRanges(values) {
  const retval = [];
  let startPart = null;

  values.forEach((value, index, self) => {
    if (value !== self[index + 1] - 1) {
      if (startPart !== null) {
        retval.push([startPart, value]);
        startPart = null;
      } else {
        retval.push(value);
      }
    } else if (startPart === null) {
      startPart = value;
    }
  });

  return retval;
}

export function parseCronHour(hours, minutes) {
  const hoursVal = hours < 10 ? '0' + hours : hours;
  const minutesVal = minutes < 10 ? '0' + minutes : minutes;

  return `${hoursVal}:${minutesVal}`;
}

export function parseCronObj(cronString) {
  const cronArr = cronString.split(' ');
  let cron = '';

  for (let i = 0; i < 5; i++) {
    if (i !== 0) {
      cron += ' ';
    }
    cron += `${cronArr[i]}`;
  }

  const cronObj = {
    cron: cron,
    timezone: cronArr.length === 6 ? cronArr[5] : null,
  };

  return cronObj;
}
export const parseSchedule = (selectedSchedule) => {
  return {
    periodSelection: 'week',
    weekDays:
      selectedSchedule?.data?.weekdays?.split(',').map((wd) => wd.toString()) ||
      [],
    allDay:
      selectedSchedule?.data?.time_start === '00:00' &&
      selectedSchedule?.data?.time_stop === '24:00',
    startTime: selectedSchedule?.data?.time_start,
    stopTime: selectedSchedule?.data?.time_stop,
    timezone: selectedSchedule?.data?.timezone,
  };
};
