import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Lock, KeyboardArrowDown } from '@mui/icons-material';

import { encodeProfileId } from 'utils/utils';
import { UNIQUE_PROFILE_FEATURES } from 'utils/globals';
import BeSafeContainer from 'components/commons/BeSafeContainer';
import BeSafeButton from 'components/commons/BeSafeButton';

const MenuConfiguration = ({ accountType, menu, parent, onChangeExpanded }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const hasLicense = (elem) => {
    return !('has_license' in elem) || elem.has_license;
  };

  const generateKey = (elem, index) => {
    let key = `${parent.name}-${index}`;

    if (elem.id && elem.name) {
      key = `${parent.name}-${elem.name}-${elem.id}`;
    }

    if (elem.name) {
      key = `${parent.name}-${elem.name}-${index}`;
    }

    return key;
  };

  const generateName = (elem) => {
    if (elem.displayName) {
      return <FormattedMessage id={elem.displayName} />;
    }
    return elem.name;
  };

  const generateRoute = (elem) => {
    if (elem) {
      const id =
        elem.menu && UNIQUE_PROFILE_FEATURES.includes(elem.name)
          ? elem.menu[0]?.id
          : elem.id;
      const encodedId = encodeProfileId(id);

      return elem.menu && UNIQUE_PROFILE_FEATURES.includes(elem.name)
        ? `/configuration/${elem.name}/${encodedId}`
        : `/configuration/${parent.name}/${encodedId}`;
    }

    return '';
  };

  const generateTitle = (elem) => {
    if (UNIQUE_PROFILE_FEATURES.includes(elem.name)) {
      return generateName(elem);
    } else if (elem.functionality_type) {
      return `${elem.name} (${elem.functionality_type.toUpperCase()})`;
    }
    return elem.name;
  };

  const isActiveMenu = (elem) => {
    return location.pathname === generateRoute(elem);
  };

  const getFilteredElements = (elements) => {
    if (accountType === 'carrier') {
      return elements.filter(
        (element) =>
          ('menu' in element && 'isCarrierConf' in element) || !element.menu
      );
    }

    return elements;
  };

  const generateClassName = (elem) => {
    let className = 'nav_menu';

    if (!hasLicense(elem)) {
      className += ' is-disabled';
    }

    if (elem.expanded) {
      className += ' expanded';
    }

    return className;
  };

  const hasOneProfile = (elem) => {
    return UNIQUE_PROFILE_FEATURES.includes(elem.name);
  };

  return (
    <>
      {getFilteredElements(menu).map((elem, index) =>
        hasOneProfile(elem) ? (
          <span
            key={generateKey(elem, index)}
            className={generateClassName(elem)}
          >
            <BeSafeButton
              className={`nav_name ${isActiveMenu(elem) ? 'is-active' : ''}`}
              text={generateName(elem)}
              icon={hasLicense(elem) ? null : <Lock fontSize="small" />}
              iconPosition="right"
              onClick={() => navigate(generateRoute(elem))}
              visible={elem.menu}
              disabled={!hasLicense(elem)}
            />
          </span>
        ) : !('menu' in elem) ? (
          <span key={generateKey(elem, index)}>
            <Link
              className={`nav_option ${isActiveMenu(elem) ? 'is-active' : ''}`}
              to={generateRoute(elem)}
            >
              {generateTitle(elem)}
            </Link>
          </span>
        ) : (
          <span
            key={generateKey(elem, index)}
            className={generateClassName(elem)}
          >
            <span className="nav_title">
              <BeSafeButton
                className="nav_name"
                text={generateName(elem)}
                icon={
                  hasLicense(elem) ? (
                    <KeyboardArrowDown className="icon" />
                  ) : (
                    <Lock fontSize="small" />
                  )
                }
                iconPosition="right"
                onClick={() => onChangeExpanded(elem)}
                visible={elem.menu}
                disabled={!hasLicense(elem)}
              />
            </span>

            <BeSafeContainer visible={hasLicense(elem)}>
              <span className="nav_options">
                <MenuConfiguration
                  accountType={accountType}
                  menu={elem.menu}
                  parent={elem}
                  onChangeExpanded={onChangeExpanded}
                />
              </span>
            </BeSafeContainer>
          </span>
        )
      )}
    </>
  );
};

export default MenuConfiguration;
