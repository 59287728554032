import { useDispatch } from 'react-redux';
import { logout } from 'app/crud/auth.crud';
import * as auth from 'store/ducks/auth.duck';
import { SetPolicyChanges } from 'store/ducks/policyChange.duck';
import { useCallback } from 'react';
import { useKeycloak } from '@react-keycloak/web';

const useLogout = () => {
  const dispatch = useDispatch();
  const { keycloak } = useKeycloak();

  const dispatchLogout = useCallback(() => {
    dispatch(auth.actions.logout());
    dispatch(SetPolicyChanges(0));
  }, [dispatch]);

  return useCallback(() => {
    logout().finally(async () => {
      document.cookie =
        'kc_session =; path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
      dispatchLogout();
      if (keycloak.authenticated) {
        await keycloak.logout({
          redirectUri: keycloak.createLoginUrl({ redirectUri: '/' }),
        });
      }
    });
  }, [dispatchLogout, keycloak]);
};

export default useLogout;
