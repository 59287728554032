import React from "react";
import { ButtonGroup, ToggleButton } from "react-bootstrap";
import { useIntl } from "react-intl";

const BeSafeButtonGroup = (props) => {
  const intl = useIntl();

  return (
    <>
      <ButtonGroup toggle className="d-flex flex-wrap group-button">
        {props.groups.map((group) => (
          <ToggleButton
            key={group}
            className="flex-grow-0"
            id={group}
            type="checkbox"
            variant="outline-primary"
            name={props.name}
            defaultChecked={false}
            checked={props.checked === group}
            value={group}
            onChange={() => props.onChange(group)}
            size="sm"
            disabled={props.disabled}
          >
            {intl.formatMessage({ id: `${ props.groupPrefix }.${ group.toUpperCase() }` }) }
          </ToggleButton>
        ))}
      </ButtonGroup>
    </>
  );
};

BeSafeButtonGroup.defaultProps = {
  checked: "",
  onChange: () => {},
  disabled: false,
};
export default BeSafeButtonGroup;
