import React from "react";
import { Modal, Form } from "react-bootstrap";
import { FormattedMessage, useIntl } from "react-intl";
import { Formik } from "formik";
import { createNetwork } from "app/crud/networks.crud";
import { errorToast, successToast } from "utils/toastUtils";
import BeSafeButton from "components/commons/BeSafeButton";

const AddMACModal = (props) => {
  const intl = useIntl();

  return (
    <Modal show={props.showModal} onHide={props.onHide} centered size="md">
      <Modal.Header closeButton>
        <Modal.Title>
          <FormattedMessage id="NETWORK_OBJECTS.MAC_ADDRESS_ADD" />
        </Modal.Title>
      </Modal.Header>

      <Formik
        initialValues={{
          name: "",
          mac: "",
        }}
        validate = {
          (values) => {
            const errors = {};

            if (!values.name || !values.name.trim()) {
              errors.name = intl.formatMessage({
                id: "GENERAL.REQUIRED_FIELD",
              });
            }

            if (values.name.length > 64) {
              errors.name = intl.formatMessage({
                id: "GENERAL.MAX_NAME_SIZE",
              });
            }

            return errors;
          }
        }
        onSubmit={(values, { setStatus, setSubmitting }) => {
          setSubmitting(true);

          let params = {
            name: values.name,
            mac_address: values.mac,
            type: "mac",
          };

          createNetwork(params)
            .then((response) => {
              props.onCreate();
              successToast({
                body: "MAC_OBJECTS.ADD_SUCCESS",
                intl: intl,
              });
            })
            .catch((err) => {
              errorToast({ body: "MAC_OBJECTS.ADD_ERROR", intl: intl });
            })
            .finally(() => setSubmitting(false));
        }}
      >
        {({
          values,
          status,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Modal.Body>
              <Form.Group controlId="name">
                <Form.Label>
                  <FormattedMessage id="GENERAL.NAME" />
                </Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  onChange={handleChange}
                  isInvalid={touched.name && errors.name}
                  className="border-secondary"
                  placeholder={intl.formatMessage({
                    id: "GENERAL.NAME_PLACEHOLDER",
                  })}
                  spellCheck="false"
                />
                {errors.name && touched.name && (
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group controlId="mac">
                <Form.Label>
                  <FormattedMessage id="NETWORK_OBJECTS.MAC_ADDRESS" />
                </Form.Label>
                <Form.Control
                  type="text"
                  name="mac"
                  onChange={handleChange}
                  isInvalid={touched.mac && errors.mac}
                  className="border-secondary"
                  placeholder="00:00:00:00:00:00"
                  spellCheck="false"
                />
                <Form.Control.Feedback type="invalid">
                  {errors.rule}
                </Form.Control.Feedback>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer className="py-3">
              <BeSafeButton
                variant="primary"
                type="submit"
                disabled={isSubmitting}
                text={<FormattedMessage id="GENERAL.SAVE" />}
              />
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddMACModal;
