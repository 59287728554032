import React from 'react';
import { Modal, Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';

import { Formik } from 'formik';
import { createNetwork, updateNetwork } from 'app/crud/networks.crud';

import { successToast, errorToast } from 'utils/toastUtils';
import { isValidEmail } from 'utils/utils';
import BeSafeButton from 'components/commons/BeSafeButton';

const AddUserModal = ({
  editMode,
  selectedNetwork,
  showModal,
  onHide,
  onCreate,
  onUpdate,
}) => {
  const intl = useIntl();

  const initialNetworkValues = {
    name: editMode ? selectedNetwork?.name : '',
    group: editMode ? selectedNetwork?.data?.group : '',
    domain: editMode ? selectedNetwork?.data?.domain : '',
    computer: editMode ? selectedNetwork?.data?.computer : '',
    network: editMode ? selectedNetwork?.ip_address : '',
    networkIPv6: editMode ? selectedNetwork?.ipv6_address : '',
    email: editMode ? selectedNetwork?.data?.email : '',
  };

  return (
    <Modal show={showModal} onHide={onHide} centered size="md">
      <Modal.Header closeButton>
        <Modal.Title>
          {editMode ? (
            <FormattedMessage id="USER_OBJECTS.EDIT" />
          ) : (
            <FormattedMessage id="USER_OBJECTS.ADD" />
          )}
        </Modal.Title>
      </Modal.Header>

      <Formik
        initialValues={{
          ...initialNetworkValues,
        }}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);

          let params = {
            name: values.name,
            email: values.email,
            group: values.group,
            domain: values.domain,
            computer: values.computer,
            ip_address: values.network,
            ipv6_address: values.networkIPv6,
            source: ['Local'],
            type: 'user',
          };

          if (editMode) {
            updateNetwork({ ...params, id: selectedNetwork.id })
              .then((response) => {
                onUpdate(params);
                successToast({
                  body: 'USER_OBJECTS.EDIT_SUCCESS',
                  intl: intl,
                });
              })
              .catch((err) => {
                errorToast({ body: err.response.data?.error, intl: intl });
              })
              .finally(() => {
                setSubmitting(false);
              });
          } else {
            createNetwork(params)
              .then((response) => {
                onCreate();
                successToast({
                  body: 'USER_OBJECTS.ADD_SUCCESS',
                  intl: intl,
                });
              })
              .catch((err) => {
                errorToast({ body: err.response.data?.error, intl: intl });
              })
              .finally(() => {
                setSubmitting(false);
              });
          }
        }}
        validate={(values) => {
          const errors = {};

          if (!values.name || !values.name.trim()) {
            errors.name = intl.formatMessage({ id: 'GENERAL.REQUIRED_FIELD' });
          }

          if (values.name.length > 64) {
            errors.name = intl.formatMessage({
              id: 'GENERAL.MAX_NAME_SIZE',
            });
          }

          if (
            (!values.network || !values.network.trim()) &&
            (!values.networkIPv6 || !values.networkIPv6.trim())
          ) {
            errors.network = intl.formatMessage({
              id: 'NETWORK_OBJECTS.FIELD_REQUIRED',
            });
            errors.networkIPv6 = intl.formatMessage({
              id: 'NETWORK_OBJECTS.FIELD_REQUIRED',
            });
          }

          if (values.email && isValidEmail(values.email) === false) {
            errors.email = intl.formatMessage({
              id: 'GENERAL.INVALID_FORMAT_EMAIL',
            });
          }

          return errors;
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          isSubmitting,
        }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Modal.Body>
              <Form.Group controlId="name">
                <Form.Label>
                  <FormattedMessage id="GENERAL.NAME" />
                </Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  onChange={handleChange}
                  isInvalid={errors.name}
                  className="border-secondary"
                  placeholder={intl.formatMessage({
                    id: 'GENERAL.NAME_PLACEHOLDER',
                  })}
                  value={values.name}
                  required
                />
                {errors.name && touched.name && (
                  <Form.Control.Feedback type="invalid">
                    {errors.name}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group controlId="email">
                <Form.Label>
                  <FormattedMessage id="NETWORK_OBJECTS.EMAIL" />
                </Form.Label>
                <Form.Control
                  type="text"
                  name="email"
                  onChange={handleChange}
                  isInvalid={touched.email && errors.email}
                  className="border-secondary"
                  value={values.email}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group controlId="group">
                <Form.Label>
                  <FormattedMessage id="NETWORK_OBJECTS.GROUP" />
                </Form.Label>
                <Form.Control
                  type="text"
                  name="group"
                  onChange={handleChange}
                  className="border-secondary"
                  placeholder={intl.formatMessage({
                    id: 'NETWORK_OBJECTS.GROUP_PLACEHOLDER',
                  })}
                  value={values.group}
                />
              </Form.Group>
              <Form.Group controlId="domain">
                <Form.Label>
                  <FormattedMessage id="NETWORK_OBJECTS.DOMAIN" />
                </Form.Label>
                <Form.Control
                  type="text"
                  name="domain"
                  onChange={handleChange}
                  className="border-secondary"
                  placeholder={intl.formatMessage({
                    id: 'NETWORK_OBJECTS.DOMAIN_PLACEHOLDER',
                  })}
                  value={values.domain}
                />
              </Form.Group>
              <Form.Group controlId="computer">
                <Form.Label>
                  <FormattedMessage id="NETWORK_OBJECTS.COMPUTER" />
                </Form.Label>
                <Form.Control
                  type="text"
                  name="computer"
                  onChange={handleChange}
                  className="border-secondary"
                  placeholder={intl.formatMessage({
                    id: 'NETWORK_OBJECTS.COMPUTER_PLACEHOLDER',
                  })}
                  value={values.computer}
                />
              </Form.Group>
              <Form.Group controlId="network">
                <Form.Label>
                  <FormattedMessage id="OBJECT_EXPLORER.NETWORK_IPV4" />
                </Form.Label>
                <Form.Control
                  type="text"
                  name="network"
                  onChange={handleChange}
                  isInvalid={errors.network}
                  className="border-secondary"
                  placeholder="2.2.2.2/32"
                  value={values.network}
                />
                {errors.network && touched.network && (
                  <Form.Control.Feedback type="invalid">
                    {errors.network}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
              <Form.Group controlId="networkIPv6">
                <Form.Label>
                  <FormattedMessage id="OBJECT_EXPLORER.NETWORK_IPV6" />
                </Form.Label>
                <Form.Control
                  type="text"
                  name="networkIPv6"
                  onChange={handleChange}
                  isInvalid={errors.networkIPv6}
                  className="border-secondary"
                  placeholder="::2/128"
                  value={values.networkIPv6}
                />
                {errors.networkIPv6 && touched.networkIPv6 && (
                  <Form.Control.Feedback type="invalid">
                    {errors.networkIPv6}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Modal.Body>
            <Modal.Footer className="py-3">
              <BeSafeButton
                variant="primary"
                type="submit"
                disabled={isSubmitting}
                text={<FormattedMessage id="GENERAL.SAVE" />}
              />
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default AddUserModal;
