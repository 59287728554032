import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const actionTypes = {
  Login: '[Login] Action',
  Logas: '[Logas] Action',
  Logout: '[Logout] Action',
  SetMaxInactiveTime: '[Change max inactive time] Action',
  SetApiKey: '[Change api key] Action',
  Register: '[Register] Action',
  UserRequested: '[Request User] Action',
  UserLoaded: '[Load User] Auth API',
};

const initialAuthState = {
  user: null,
};

export const reducer = persistReducer(
  { storage, key: 'auth', whitelist: ['user'] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        return { user: action.payload.user };
      }
      case actionTypes.Logas: {
        return { ...state, user: action.payload.user };
      }
      case actionTypes.Logout: {
        return initialAuthState;
      }
      case actionTypes.SetMaxInactiveTime: {
        return {
          ...state,
          user: {
            ...state.user,
            max_inactive_time: action.payload.maxInactiveTime,
          },
        };
      }
      case actionTypes.SetApiKey: {
        return {
          ...state,
          user: { ...state.user, api_key: action.payload.apiKey },
        };
      }
      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        return { ...state, user };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (user) => ({
    type: actionTypes.Login,
    payload: { user },
  }),
  logas: (user) => ({ type: actionTypes.Logas, payload: { user } }),
  logout: () => ({ type: actionTypes.Logout }),
  setMaxInactiveTime: (maxInactiveTime) => ({
    type: actionTypes.SetMaxInactiveTime,
    payload: { maxInactiveTime },
  }),
  setApiKey: (apiKey) => ({ type: actionTypes.SetApiKey, payload: { apiKey } }),
  requestUser: (user) => ({
    type: actionTypes.UserRequested,
    payload: { user },
  }),
  fulfillUser: (user) => ({ type: actionTypes.UserLoaded, payload: { user } }),
};
